<template src="./FileIcon.html" />
<style lang="scss" scoped src="./FileIcon.scss"></style>
<script>
import { defineComponent } from '@vue/composition-api'
import { TAG_ID } from '@/constants/constant'

export default defineComponent({
  name: 'FileIcon',
  props: {
    tagId: {
      type: Number,
      require: true
    }
  },
  setup() {
    return {
      TAG_ID
    }
  }
})
</script>

<template src="./IdentificationStatus.html" />
<style lang="scss" scoped src="./IdentificationStatus.scss"></style>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import { ID_CHECKED_STATUS } from '@/constants/constant'

export default defineComponent({
  name: 'IdentificationStatus',
  setup(_, { root }) {
    return {
      isSp: computed(() => root.$store.getters.isSp),
      identificationStatus: computed(
        () => root.$store.getters.identificationStatus
      ),
      identificationDenialReasonText: computed(
        () => root.$store.getters.identificationDenialReasonText
      ),
      ID_CHECKED_STATUS
    }
  }
})
</script>

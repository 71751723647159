<template src="./OwnerRegister.html" />
<style lang="scss" scoped src="./OwnerRegister.scss"></style>
<script>
import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch
} from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import Camera from '@/compornents/Camera'
import { ID_CHECKED_STATUS, LOADING_TYPE_ATTACH } from '@/constants/constant'
import { canPreview, getExt } from '@/utils/common'
import { createImage, isImage, toBlob } from '@/utils/image'
import {
  GENDER_LIST,
  PREFECTURE_LIST,
  USER_CATEGORY,
  USER_CATEGORY_NAMES,
  SUB_CATEGORY,
  COPY_RIGHT
} from '@/constants/constant'
import {
  GET_POST_CODE,
  GET_USER_SEARCH,
  GET_OFFICE_SEARCH,
  GET_OTHER_OFFICE_SEARCH,
  POST_USER_PROFILE,
  POST_BUSINESS_IDENTIFICATION,
  SET_LOADING,
  GET_KAIGO_KOUHYOU,
  FETCH_ME
} from '@/store/types'
import { getNowFullString } from '@/utils/date'
import { putS3Attachment } from '@/comporsables/useAws'
import { getThisYear } from '@/utils/date'
import '@/comporsables/useVeeValidate'
import VueRecaptcha from 'vue-recaptcha'

export default defineComponent({
  name: 'OwnerRegister',
  title() {
    return '事業者新規会員登録'
  },
  components: {
    VueRecaptcha,
    HeaderRight,
    AvatarImg,
    Camera
  },
  setup(_, { root }) {
    const recaptchaSiteKey = ref(process.env.VUE_APP_RECAPTCHA_SITE_KEY_V2)
    const { me } = useMe(root)
    if (!me.is_login) {
      root.$router.push({ name: 'login' })
    }
    if (me.last_name) {
      root.$router.push({ name: 'home' })
    }

    const kaigo_kouhyou = computed(() => root.$store.getters.kaigo_kouhyou)
    const isSp = computed(() => root.$store.getters.isSp)

    const step = computed(() => parseInt(root.$route.params.step))
    const form = reactive({
      category_id: '',
      sub_category_id: '',
      other_sub_category_name: '',
      last_name: '',
      last_name_kana: '',
      first_name: '',
      first_name_kana: '',
      min_care_id: '',
      email: me.email,
      gender_id: 'male',
      year: '1970',
      month: '01',
      day: '01',
      position: '',
      qualification: '',
      office_number: '',
      other_office_number: '',
      organization_name: '',
      company_name_kana: '',
      postcode: '',
      prefecture_id: '',
      city: '',
      block: '',
      building: '',
      homepage_url: '',
      business_phone: '',
      home_phone: '',
      mobile_phone: '',
      agreement: false
    })
    if (step.value > 1 && form.category_id === '') {
      root.$router.push({ name: 'owner_register', params: { step: 1 } })
    }

    const selectCategory = category_id => {
      form.category_id = category_id
      form.sub_category_id = ''
      form.other_sub_category_name = ''
    }

    const showSubCategoryError = ref(false)
    const clickNext = next_step => {
      if (
        next_step === 3 &&
        [USER_CATEGORY.BUSINESS, USER_CATEGORY.MEDICAL].includes(
          form.category_id
        ) &&
        form.sub_category_id === ''
      ) {
        showSubCategoryError.value = true
        return
      }

      root.$router.push({
        name: 'owner_register',
        params: { step: next_step }
      })
    }

    const clickPrev = () => {
      if (step.value === 1) {
        return
      }
      root.$router.push({
        name: 'owner_register',
        params: { step: step.value - 1 }
      })
    }

    const categoryName = computed(() => USER_CATEGORY_NAMES[form.category_id])
    const subCategoryName = computed(() => {
      if (form.category_id === USER_CATEGORY.BUSINESS) {
        return SUB_CATEGORY.BUSINESS.find(c => c.value === form.sub_category_id)
          .label
      }
      if (form.category_id === USER_CATEGORY.MEDICAL) {
        return SUB_CATEGORY.MEDICAL.find(c => c.value === form.sub_category_id)
          .label
      }
      if (form.category_id === USER_CATEGORY.OTHER) {
        return form.other_sub_category_name
      }
    })
    const genderName = computed(
      () => GENDER_LIST.find(g => g.value === form.gender_id).label
    )

    const postProfile = () => {
      let param = {
        postcode: form.postcode,
        prefecture_id: form.prefecture_id,
        city: form.city,
        block: form.block,
        building: form.building,
        birthday: `${form.year}-${form.month}-${form.day}`,
        category_id: form.category_id,
        sub_category_id: form.sub_category_id,
        other_sub_category_name: form.other_sub_category_name,
        office_number: form.office_number,
        other_office_number: form.other_office_number,
        first_name: form.first_name,
        first_name_kana: form.first_name_kana,
        gender_id: form.gender_id,
        last_name: form.last_name,
        last_name_kana: form.last_name_kana,
        min_care_id: form.min_care_id,
        organization_name: form.organization_name,
        position: form.position,
        qualification: form.qualification,
        homepage_url: form.homepage_url,
        business_phone: form.business_phone,
        home_phone: form.home_phone,
        mobile_phone: form.mobile_phone,
        recaptchaToken: recaptchaToken
      }
      root.$store
        .dispatch(POST_USER_PROFILE, param)
        .then(() => {
          return root.$store.dispatch(FETCH_ME)
        })
        .then(() => root.$router.push({ name: 'business_profile_register' }))
        .catch(() => undefined)
    }

    const thisYear = getThisYear()
    let years = []
    for (let i = 1950; i <= thisYear; i++) {
      years = [...years, i]
    }

    const response = reactive({
      jiscode: null,
      no: null,
      pref: null,
      city: null,
      company_name: null,
      company_name_kana: null,
      service_name: null,
      address: null,
      building: null,
      tel: null,
      fax: null,
      corporate_no: null,
      corporate_name: null,
      office_number: null,
      available_week: null,
      available_memo: null,
      capacity: null,
      url: null
    })

    const autojiscode = ref(false)

    watch(
      () => form.office_number,
      v => {
        root.$store
          .dispatch(GET_KAIGO_KOUHYOU, v)
          .then(() => {
            form.office_number = v
            const prefCode = root.$store.getters.kaigo_kouhyou.pref
            const prefecture = PREFECTURE_LIST.find(
              item => item.label === prefCode
            )
            if (prefecture) {
              form.prefecture_id = prefecture.value
            }
            form.postcode = root.$store.getters.kaigo_kouhyou.zipcode
            form.organization_name =
              root.$store.getters.kaigo_kouhyou.company_name
            form.company_name_kana =
              root.$store.getters.kaigo_kouhyou.company_name_kana
            form.city = root.$store.getters.kaigo_kouhyou.city
            form.block = root.$store.getters.kaigo_kouhyou.address
            form.building = root.$store.getters.kaigo_kouhyou.building
            form.business_phone = root.$store.getters.kaigo_kouhyou.tel
            form.homepage_url = root.$store.getters.kaigo_kouhyou.url
            autojiscode.value = true
          })
          .catch(() => {
            console.error('Error fetching')
          })
      }
    )

    const minCareIdValid = ref('')

    watch(
      () => form.min_care_id,
      v => {
        if (v.length < 6) {
          return
        }
        minCareIdValid.value = ''
        root.$store
          .dispatch(GET_USER_SEARCH, form.min_care_id)
          .then(() => {
            minCareIdValid.value = root.$store.getters.user ? 'NG' : 'OK'
          })
          .catch(() => {
            minCareIdValid.value = 'NG'
          })
      }
    )

    const officeNumberValid = ref('')
    const office = ref('')
    watch(
      () => form.office_number,
      v => {
        if (v.length < 9) {
          return
        }
        officeNumberValid.value = ''
        office.value = ''
        root.$store
          .dispatch(GET_OFFICE_SEARCH, form.office_number)
          .then(() => {
            office.value = root.$store.getters.office
            officeNumberValid.value =
              Object.keys(office.value).length > 0 ? 'NG' : 'OK'
          })
          .catch(() => {
            officeNumberValid.value = 'NG'
          })
      }
    )

    const otherOfficeNumberValid = ref('')
    const otherOffice = ref('')
    watch(
      () => form.other_office_number,
      v => {
        if (v.length < 11) {
          return
        }
        otherOfficeNumberValid.value = ''
        otherOffice.value = ''
        root.$store
          .dispatch(GET_OTHER_OFFICE_SEARCH, form.other_office_number)
          .then(() => {
            otherOffice.value = root.$store.getters.otherOffice
            otherOfficeNumberValid.value =
              Object.keys(otherOffice.value).length > 0 ? 'NG' : 'OK'
          })
          .catch(() => {
            otherOfficeNumberValid.value = 'NG'
          })
      }
    )

    const recaptchaToken = ref('')
    const onVerify = response => {
      if (response !== '') {
        recaptchaToken.value = response
      }
    }

    // const recaptcha = ref(null)
    // onMounted(() => {
    //   console.log(recaptcha.value)
    // })

    const onExpired = () => {
      // console.log(recaptcha.value.reset())
      recaptchaToken.value = ''
    }

    const postCodeIsInvalid = ref(false)
    const selectedPrefecture = computed(() =>
      form.prefecture_id
        ? PREFECTURE_LIST.find(p => p.value === parseInt(form.prefecture_id))
            .label
        : ''
    )

    watch(
      () => form.postcode,
      v =>
        root.$store
          .dispatch(GET_POST_CODE, v)
          .then(() => {
            postCodeIsInvalid.value = false
            if (!autojiscode.value) {
              form.prefecture_id = root.$store.getters.address.prefecture_id
              form.city = root.$store.getters.address.city
              form.block = root.$store.getters.address.block
            }
          })
          .catch(() => {
            postCodeIsInvalid.value = true
          })
    )

    const showCameraMenu = ref(false)
    const showCameraNo = ref(0)
    const attachFile1 = ref('')
    const uploadFileData1 = ref('')
    const fileRef1 = ref(null)
    const inputFile = e => {
      const files = e.target.files || e.dataTransfer.files
      if (!isImage(getExt(files[0].name))) {
        root.$notify({
          title: '',
          message: '画像ファイルを選択してください',
          type: 'error'
        })
        switch (showCameraNo.value) {
          case 1:
            fileRef1.value.value = null
            break
        }
        return
      }
      showCameraMenu.value = false
      switch (showCameraNo.value) {
        case 1:
          attachFile1.value = files[0]
          createImage(attachFile1.value, uploadFileData1)
          break
      }
    }
    const canPreviewFile = no => {
      switch (no) {
        case 1:
          return attachFile1.value ? canPreview(attachFile1.value.name) : false
      }
    }

    const canUpload = computed(() => uploadFileData1.value)
    const upload = () => {
      root.$store.dispatch(SET_LOADING, {
        type: LOADING_TYPE_ATTACH,
        status: true
      })

      const attachFileKey1 = `businessidentification-${
        me.user_id
      }/${getNowFullString()}_${attachFile1.value.name}`

      const officeNumber = form.office_number || form.other_office_number

      Promise.all([
        putS3Attachment(
          attachFileKey1,
          toBlob(uploadFileData1.value, attachFile1.value.type),
          attachFile1.value.type
        )
      ])
        .then(() => {
          root.$store
            .dispatch(POST_BUSINESS_IDENTIFICATION, {
              identity_image_front_side_url: attachFileKey1,
              company_name: form.organization_name,
              office_number: officeNumber
            })
            .then(() => {
              showCameraMenu.value = false
              root.$store.dispatch(SET_LOADING, {
                type: LOADING_TYPE_ATTACH,
                status: false
              })
              root.$notify({
                title: '',
                message: '事業所許認可証をアップロードしました',
                type: 'success'
              })
            })
        })
        .catch(err => {
          console.log(err)
          root.$notify({
            title: '',
            message: 'ファイルのアップロードに失敗しました',
            type: 'error'
          })
        })
    }

    const clearFile = no => {
      switch (no) {
        case 1:
          attachFile1.value = ''
          uploadFileData1.value = ''
          fileRef1.value.value = null
          break
      }
    }

    return {
      loading: computed(() => root.$store.getters.loading),
      me,
      isSp,
      kaigo_kouhyou,
      autojiscode,
      ID_CHECKED_STATUS,
      canPreviewFile,
      showCameraNo,
      inputFile,
      uploadFileData1,
      showCameraMenu,
      attachFile1,
      upload,
      canUpload,
      clearFile,
      fileRef1,
      USER_CATEGORY,
      GENDER_LIST,
      PREFECTURE_LIST,
      selectCategory,
      recaptchaSiteKey,
      step,
      form,
      categoryName,
      subCategoryName,
      genderName,
      years,
      minCareIdValid,
      officeNumberValid,
      otherOfficeNumberValid,
      recaptchaToken,
      clickNext,
      clickPrev,
      postProfile,
      onVerify,
      onExpired,
      postCodeIsInvalid,
      selectedPrefecture,
      SUB_CATEGORY,
      COPY_RIGHT,
      showSubCategoryError,
      response
    }
  }
})
</script>

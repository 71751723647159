import { getEnv } from '@/utils/common'

export const ENV_LOCAL = 'local'
export const ENV_DEV = 'dev'
export const ENV_PRD = 'prod'

export const API_DOMAIN = 'systems.minna-de-care.com'

let apiUri = ''
let brandSumple = ''
switch (getEnv()) {
  case ENV_PRD:
    apiUri = 'https://' + API_DOMAIN
    brandSumple = 'https://service.minna-de-care.com/detail_sumple.php'
    break
  case ENV_DEV:
    apiUri = 'https://dev.' + API_DOMAIN
    brandSumple = 'https://dev.service.minna-de-care.com/detail_sumple.php'
    break
  default:
    apiUri = ''
    brandSumple = ''
    break
}
export const API_URI = apiUri
export const BRAND_URI = brandSumple

export const ENDPOINT_SANCTUM_CSRF_COOKIE = API_URI + '/sanctum/csrf-cookie'
export const ENDPOINT_LOGIN = API_URI + '/api/auth/login'
export const ENDPOINT_LOGOUT = API_URI + '/api/auth/logout'
export const ENDPOINT_ME = API_URI + '/api/v1/user/me'
export const ENDPOINT_MESSAGE = API_URI + '/api/v1/message'
export const ENDPOINT_MESSAGE_READ = API_URI + '/api/v1/message/read'
export const ENDPOINT_MESSAGES = API_URI + '/api/v1/messages'
export const ENDPOINT_OWNER_REGISTER = API_URI + '/api/v1/user/ownerRegister'
export const ENDPOINT_FAMILY_REGISTER = API_URI + '/api/v1/user/familyRegister'
export const ENDPOINT_USER_REGISTER = API_URI + '/api/v1/user/register'
export const ENDPOINT_USER_REGISTER_CONFIRM =
  API_URI + '/api/v1/user/register/confirm'
export const ENDPOINT_OWNER_REGISTER_CONFIRM =
  API_URI + '/api/v1/user/owner_confirm'
export const ENDPOINT_FAMILY_REGISTER_CONFIRM =
  API_URI + '/api/v1/user/family_confirm'
export const ENDPOINT_USER_PROFILE = API_URI + '/api/v1/user/profile'
export const ENDPOINT_USER_SEARCH = API_URI + '/api/v1/user/search'
export const ENDPOINT_OFFICE_SEARCH = API_URI + '/api/v1/user/office_search'
export const ENDPOINT_OTHER_OFFICE_SEARCH =
  API_URI + '/api/v1/user/other_office_search'
export const ENDPOINT_CONTACT = API_URI + '/api/v1/contact'
export const ENDPOINT_CONTACTS = API_URI + '/api/v1/contacts'
export const ENDPOINT_CHAT = API_URI + '/api/v1/chat'
export const ENDPOINT_CHATS = API_URI + '/api/v1/chats'
export const ENDPOINT_CHAT_MEMBERS = API_URI + '/api/v1/chat_members'
export const ENDPOINT_CHAT_MEMBER = API_URI + '/api/v1/chat_member'
export const ENDPOINT_CHAT_DELETE_REQUEST =
  API_URI + '/api/v1/chat_delete_request'
export const ENDPOINT_CHAT_OWNER = API_URI + '/api/v1/chat_owner'
export const ENDPOINT_RECORDS = API_URI + '/api/v1/records'
export const ENDPOINT_RECORD = API_URI + '/api/v1/record'
export const ENDPOINT_FILES = API_URI + '/api/v1/files'
export const ENDPOINT_FILE = API_URI + '/api/v1/file'
export const ENDPOINT_FORGOT_PASSWORD = API_URI + '/api/v1/user/forgot/password'
export const ENDPOINT_POSTCODE_API = 'https://zip-cloud.appspot.com/api/search'
export const ENDPOINT_SCHEDULES = API_URI + '/api/v1/schedules'
export const ENDPOINT_SCHEDULE = API_URI + '/api/v1/schedule'
export const ENDPOINT_RESET_PASSWORD = API_URI + '/api/v1/user/reset/password'
export const ENDPOINT_BUSINESS_PROFILE = API_URI + '/api/v1/business/profile'
export const ENDPOINT_USER_IDENTIFICATION =
  API_URI + '/api/v1/user/identification'
export const ENDPOINT_STAFF = API_URI + '/api/v1/staff'
export const ENDPOINT_STAFFS = API_URI + '/api/v1/staffs'
export const ENDPOINT_PR = API_URI + '/api/v1/business/pr_register'
export const ENDPOINT_INVITED_STAFFS = API_URI + '/api/v1/invited_staffs'
export const ENDPOINT_STAFF_CHATS = API_URI + '/api/v1/staff_chats'
export const ENDPOINT_STAFF_HISTORIES = API_URI + '/api/v1/staff_histories'
export const ENDPOINT_STAFF_MONTHLY_USAGE =
  API_URI + '/api/v1/staff_monthly_usage'
export const ENDPOINT_STAFF_MONTHLY_USAGE_DETAIL =
  API_URI + '/api/v1/staff_monthly_usage_detail'
export const ENDPOINT_SAMPLE = API_URI + '/api/v1/sample'
export const ENDPOINT_MAILON = API_URI + '/api/v1/mailon'
export const ENDPOINT_MAILOFF = API_URI + '/api/v1/mailoff'
export const ENDPOINT_DELETE_OFFICE = API_URI + '/api/v1/delete_office'
export const ENDPOINT_KAIGO_KOUHYOU = API_URI + '/api/v1/user/kaigo_kouhyou'
export const ENDPOINT_BUSINESS_IDENTIFICATION =
  API_URI + '/api/v1/user/business_identification'
export const ENDPOINT_MAPON = API_URI + '/api/v1/mapon'
export const ENDPOINT_MAPOFF = API_URI + '/api/v1/mapoff'
export const ENDPOINT_REGIST_PLATINUM =
  API_URI + '/api/v1/business/regist_platinum'
export const ENDPOINT_CANCELL_PLATINUM =
  API_URI + '/api/v1/business/cancell_platinum'
export const ENDPOINT_BUSINESS_PLATINUM =
  API_URI + '/api/v1/business/get_platinum'
export const ENDPOINT_POST_TMP_PLATINUM =
  API_URI + '/api/v1/business/post_tmp_platinum'
export const ENDPOINT_UPLODE_TMP_IMG =
  API_URI + '/api/v1/business/uplode_tmp_imgs'
export const ENDPOINT_GET_OPTIONS = API_URI + '/api/v1/business/get_options'
export const ENDPOINT_GET_IMGS = API_URI + '/api/v1/business/get_imgs'
export const ENDPOINT_BUSINESS_TMP_PLATINUM =
  API_URI + '/api/v1/business/get_tmp_platinum'
export const ENDPOINT_GET_TMP_OPTIONS =
  API_URI + '/api/v1/business/get_tmp_options'
export const ENDPOINT_GET_TMP_IMGS = API_URI + '/api/v1/business/get_tmp_imgs'

<template src="./Manage.html" />
<style lang="scss" scoped src="./Manage.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import ManageMenu from '@/compornents/ManageMenu/ManageMenu'
import StaffHistoryModal from '@/compornents/StaffHistoryModal/StaffHistoryModal'
import {
  CLEAR_STAFF_SEARCH_WORD,
  DELETE_STAFF,
  GET_STAFF_CHATS,
  GET_STAFFS,
  INPUT_STAFF_SEARCH_WORD,
  PATCH_CHAT_MEMBER,
  UNPATCH_CHAT_MEMBER,
  PATCH_STAFF
} from '@/store/types'
import { CHAT_STATUS, STAFF_HISTORY_STATUS } from '@/constants/constant'

export default defineComponent({
  name: 'Manage',
  title() {
    return '介護事業者管理画面'
  },
  components: {
    HeaderRight,
    AvatarImg,
    ManageMenu,
    StaffHistoryModal
  },
  setup(_, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const isManage = computed(() => root.$store.getters.isManage)
    // SPまたは事業管理者以外の場合はホームへ
    if (isSp.value || !isManage.value) {
      root.$router.push({ name: 'home' })
    }

    const staffs = computed(() => root.$store.getters.staffs)
    const staffsTotalCount = computed(
      () => root.$store.getters.staffs_total_count
    )
    const staff_chats = computed(() => root.$store.getters.staff_chats)

    const selectedUserId = ref(0)
    const pageStaffChats = ref(1)
    const pageStaffs = ref(1)

    const selectUser = user_id => {
      if (selectedUserId.value !== user_id) {
        selectedUserId.value = user_id
        getStaffChats()
      }
    }

    const getStaffs = () => {
      root.$store
        .dispatch(GET_STAFFS, {
          page: pageStaffs.value
        })
        .then(() => {
          if (!selectedUserId.value) {
            selectedUserId.value =
              staffs.value.length > 0 ? staffs.value[0].user_id : 0
          }
          getStaffChats()
        })
    }
    getStaffs()

    const getStaffChats = () => {
      if (selectedUserId.value) {
        root.$store.dispatch(GET_STAFF_CHATS, {
          user_id: selectedUserId.value,
          page: pageStaffChats.value
        })
      }
    }
    getStaffChats()

    const staffSearchWord = computed(
      () => root.$store.getters.staff_search_word
    )
    const inputStaffSearchWord = e =>
      root.$store.dispatch(INPUT_STAFF_SEARCH_WORD, e.target.value)
    const clearStaffSearchWord = () =>
      root.$store.dispatch(CLEAR_STAFF_SEARCH_WORD)

    const staffChatsTotalPage = computed(
      () => root.$store.getters.staff_chats_total_page
    )
    const nextStaffChatsPage = () => {
      if (pageStaffChats.value === staffChatsTotalPage.value) {
        return
      }
      pageStaffChats.value++
      getStaffChats()
    }
    const prevStaffChatsPage = () => {
      if (pageStaffChats.value === 1) {
        return
      }
      pageStaffChats.value--
      getStaffChats()
    }

    const staffsTotalPage = computed(
      () => root.$store.getters.staffs_total_page
    )
    const nextStaffsPage = () => {
      if (pageStaffs.value === staffsTotalPage.value) {
        return
      }
      pageStaffs.value++
      getStaffChats()
    }
    const prevStaffsPage = () => {
      if (pageStaffs.value === 1) {
        return
      }
      pageStaffs.value--
      getStaffs()
    }

    const convertLabel = status => {
      switch (status) {
        case STAFF_HISTORY_STATUS.INVITING: // 招待中
          return 'label02'
        case STAFF_HISTORY_STATUS.PARTICIPATED: // 参加済
          return 'label03'
        case CHAT_STATUS.SLEEP: // 休眠中
          return 'label04'
        case STAFF_HISTORY_STATUS.LEAVE: // 退室済
          return 'label05'
        default:
          return ''
      }
    }

    const patchChatMember = () => {
      if (!selectedUserId.value) {
        return
      }
      showModal.value = false
      root.$store
        .dispatch(PATCH_CHAT_MEMBER, {
          user_id: selectedUserId.value,
          chat_id: addGroupChatId.value
        })
        .then(() => {
          root.$notify({
            title: '',
            message: 'グループチャットに参加させました',
            type: 'success'
          })
          getStaffChats()
        })
    }
    const unpatchChatMember = () => {
      if (!selectedUserId.value) {
        return
      }
      showModal.value = false
      root.$store
        .dispatch(UNPATCH_CHAT_MEMBER, {
          user_id: selectedUserId.value,
          chat_id: deleteGroupChatId.value
        })
        .then(() => {
          root.$notify({
            title: '',
            message: 'グループチャットの管理者にメンバーの退出申請をしました',
            type: 'success'
          })
          getStaffChats()
        })
    }

    const showMenu = ref(false)
    const toggleShowMenu = () => {
      console.log('toggle!!')
      showMenu.value = !showMenu.value
    }
    const clearShowMenu = () => {
      console.log('clearShowMenu!!')
      showMenu.value = false
      addGroupChatId.value = 0
      deleteGroupChatId.value = 0
      stopStaff.value = null
      deleteStaff.value = null
      restartStaff.value = null
    }

    const showModal = ref(false)
    const addGroupChatId = ref(0)
    const showAddGroupModal = chatId => {
      addGroupChatId.value = chatId
      showModal.value = true
    }
    const deleteGroupChatId = ref(0)
    const showDeleteGroupModal = chatId => {
      deleteGroupChatId.value = chatId
      showModal.value = true
    }

    const deleteStaff = ref(null)
    const showDeleteStaffModal = user => {
      deleteStaff.value = user
      showModal.value = true
      showMenu.value = false
    }
    const delStaff = () => {
      showModal.value = false
      root.$store
        .dispatch(DELETE_STAFF, {
          user_id: deleteStaff.value.user_id
        })
        .then(() => {
          root.$notify({
            title: '',
            message: 'スタッフを削除しました',
            type: 'success'
          })
          getStaffs()
          getStaffChats()
          deleteStaff.value = null
        })
    }

    const stopStaff = ref(null)
    const showStopStaffModal = user => {
      stopStaff.value = user
      showModal.value = true
      showMenu.value = false
    }

    const restartStaff = ref(null)
    const showRestartStaffModal = user => {
      restartStaff.value = user
      showModal.value = true
      showMenu.value = false
    }

    const patchStaff = is_stopped => {
      showModal.value = false
      root.$store
        .dispatch(PATCH_STAFF, {
          user_id: stopStaff.value
            ? stopStaff.value.user_id
            : restartStaff.value.user_id,
          is_stopped: is_stopped
        })
        .then(() => {
          root.$notify({
            title: '',
            message: 'スタッフを' + (is_stopped ? '停止' : '再開') + 'しました',
            type: 'success'
          })
          restartStaff.value = null
          stopStaff.value = null
          getStaffs()
          getStaffChats()
        })
    }

    const showStaffHistoryModal = ref(false)
    const showStaffHistoryUserId = ref(0)
    const openStaffHistory = user_id => {
      showStaffHistoryModal.value = true
      showStaffHistoryUserId.value = user_id
      console.log(showStaffHistoryUserId.value, user_id)
    }

    return {
      loading: computed(() => root.$store.getters.loading),
      staffs,
      staff_chats,
      selectedUserId,
      staffsTotalPage,
      staffsTotalCount,
      staffSearchWord,
      inputStaffSearchWord,
      clearStaffSearchWord,
      staffChatsTotalPage,
      pageStaffChats,
      nextStaffChatsPage,
      prevStaffChatsPage,
      pageStaffs,
      nextStaffsPage,
      prevStaffsPage,
      selectUser,
      convertLabel,
      patchChatMember,
      unpatchChatMember,
      toggleShowMenu,
      showMenu,
      clearShowMenu,
      showModal,
      showAddGroupModal,
      showDeleteGroupModal,
      addGroupChatId,
      deleteGroupChatId,
      stopStaff,
      deleteStaff,
      restartStaff,
      showStopStaffModal,
      showDeleteStaffModal,
      showRestartStaffModal,
      delStaff,
      patchStaff,
      showStaffHistoryModal,
      openStaffHistory,
      showStaffHistoryUserId,
      STAFF_HISTORY_STATUS
    }
  }
})
</script>

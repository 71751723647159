import Vue from 'vue'
import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider
} from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja.json'
import {
  alpha_num,
  confirmed,
  email,
  numeric,
  required,
  length,
  min,
  max
} from 'vee-validate/dist/rules'
import { URL_REG_EXP } from '@/constants/constant'

localize('ja', ja)
extend('required', required)
extend('alpha_num', alpha_num)
extend('confirmed', confirmed)
extend('email', email)
extend('numeric', numeric)
extend('length', length)
extend('min', min)
extend('max', max)
extend('kana', {
  params: [],
  message: '{_field_}はカタカナで入力して下さい。',
  validate(value) {
    return value.match(/^([ァ-ヴ][ァ-ヴー・]*)$/)
  }
})
extend('password', {
  params: [],
  message: '使用できない文字が使われています。',
  validate(value) {
    return value.match(
      /^([!"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\]^_`abcdefghijklmnopqrstuvwxyz{|}~]*)$/
    )
  }
})
extend('password_use_char1', {
  params: [],
  message: '半角英字小文字を1文字以上入力してください',
  validate(value) {
    return /[a-z]/.test(value)
  }
})
extend('password_use_char2', {
  params: [],
  message: '半角英字大文字を1文字以上入力してください',
  validate(value) {
    return /[A-Z]/.test(value)
  }
})
extend('password_use_char3', {
  params: [],
  message: '半角数字を1文字以上入力してください',
  validate(value) {
    return /[0-9]/.test(value)
  }
})
extend('postcode', {
  params: [],
  message: '郵便番号はハイフン無しの形式で入力してください。',
  validate(value) {
    return value.match(/^([0-9]{7})$/)
  }
})
extend('min_care_id', {
  params: [],
  message: 'みんケアIDは半角英数字アンダースコアハイフンのみで入力して下さい',
  validate(value) {
    return value.match(/^[0-9a-zA-Z-_]*$/)
  }
})
extend('tel', {
  params: [],
  message: '{_field_}は半角数字、ハイフンのみで入力してください。',
  validate(value) {
    return value.match(/^([0-9-])+$/)
  }
})
extend('url', {
  params: [],
  message: '{_field_}の形式が正しくありません',
  validate(value) {
    return value.match(URL_REG_EXP)
  }
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

import * as http from '@/utils/api'
import { PATCH_FORGOT_PASSWORD, POST_FORGOT_PASSWORD } from './types'
import { ENDPOINT_FORGOT_PASSWORD } from '@/constants/endpoint'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    [POST_FORGOT_PASSWORD]: async ({ dispatch }, params) => {
      await http.post(ENDPOINT_FORGOT_PASSWORD, params).catch(error => {
        http.handleError(dispatch, error)
        throw new Error()
      })
    },
    [PATCH_FORGOT_PASSWORD]: async ({ dispatch }, params) => {
      await http.patch(ENDPOINT_FORGOT_PASSWORD, params).catch(error => {
        http.handleError(dispatch, error)
      })
    }
  }
}

import Vue from 'vue'
import Vuex from 'vuex'
import loading from './loading'
import me from './me'
import user from './user'
import message from './message'
import messages from './messages'
import contact from './contact'
import chat from './chat'
import familyChat from './familyChat'
import report from './report'
import records from './records'
import files from './files'
import forgotPassword from './forgotPassword'
import postCode from './postCode'
import schedule from './schedule'
import businessProfile from './businessProfile'
import staff from './staff'
import pr from './pr'
import identification from './identification'
import businessIdentification from './businessIdentification'
import kaigoKouhyou from './kaigoKouhyou'
import businessPlatinum from './businessPlatinum'
import sample from './sample'
import { isLocal } from '@/utils/common'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    loading,
    me,
    user,
    message,
    messages,
    contact,
    chat,
    familyChat,
    report,
    records,
    files,
    forgotPassword,
    postCode,
    schedule,
    businessProfile,
    staff,
    identification,
    kaigoKouhyou,
    businessIdentification,
    pr,
    businessPlatinum,
    sample
  },
  strict: isLocal
})

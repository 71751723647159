<template src="./ManageIdentificationSelect.html" />
<style lang="scss" scoped src="./ManageIdentificationSelect.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import Camera from '@/compornents/Camera'
import { ID_CHECKED_STATUS, LOADING_TYPE_ATTACH } from '@/constants/constant'
import { canPreview, getExt } from '@/utils/common'
import { createImage, isImage, toBlob } from '@/utils/image'
import {
  GET_BUSINESS_IDENTIFICATION,
  POST_BUSINESS_IDENTIFICATION,
  SET_LOADING
} from '@/store/types'
import { getNowFullString } from '@/utils/date'
import { putS3Attachment } from '@/comporsables/useAws'

export default defineComponent({
  name: 'ManageIdentificationSelect',
  components: {
    HeaderRight,
    AvatarImg,
    Camera
  },
  title() {
    return '事業所許認可証'
  },
  setup(_, { root }) {
    const { me } = useMe(root)
    const isManager = computed(() => root.$store.getters.isManager)
    const businessIdentificationStatus = computed(
      () => root.$store.getters.businessIdentificationStatus
    )
    const isSp = computed(() => root.$store.getters.isSp)
    if (
      ![
        ID_CHECKED_STATUS.UNAUTHENTICATED,
        ID_CHECKED_STATUS.DENIED,
        ID_CHECKED_STATUS.INCOMPLETE
      ].includes(businessIdentificationStatus.value)
    ) {
      root.$router.push({ name: 'manage_identification' })
    }

    const showCameraMenu = ref(false)
    const showCameraNo = ref(0)
    const attachFile1 = ref('')
    const uploadFileData1 = ref('')
    const fileRef1 = ref(null)
    const inputFile = e => {
      const files = e.target.files || e.dataTransfer.files
      if (!isImage(getExt(files[0].name))) {
        root.$notify({
          title: '',
          message: '画像ファイルを選択してください',
          type: 'error'
        })
        switch (showCameraNo.value) {
          case 1:
            fileRef1.value.value = null
            break
        }
        return
      }
      showCameraMenu.value = false
      switch (showCameraNo.value) {
        case 1:
          attachFile1.value = files[0]
          createImage(attachFile1.value, uploadFileData1)
          break
      }
    }
    const canPreviewFile = no => {
      switch (no) {
        case 1:
          return attachFile1.value ? canPreview(attachFile1.value.name) : false
      }
    }

    const canUpload = computed(() => uploadFileData1.value)
    const upload = () => {
      root.$store.dispatch(SET_LOADING, {
        type: LOADING_TYPE_ATTACH,
        status: true
      })

      const attachFileKey1 = `businessidentification-${
        me.user_id
      }/${getNowFullString()}_${attachFile1.value.name}`

      Promise.all([
        putS3Attachment(
          attachFileKey1,
          toBlob(uploadFileData1.value, attachFile1.value.type),
          attachFile1.value.type
        )
      ])
        .then(() => {
          root.$store
            .dispatch(POST_BUSINESS_IDENTIFICATION, {
              identity_image_front_side_url: attachFileKey1,
              company_name: me.organization_name,
              office_number: me.office_number,
              other_office_number: me.other_office_number
            })
            .then(() => {
              root.$store.dispatch(GET_BUSINESS_IDENTIFICATION).then(() => {
                showCameraMenu.value = false
                root.$store.dispatch(SET_LOADING, {
                  type: LOADING_TYPE_ATTACH,
                  status: false
                })
                location.reload()
              })
            })
        })
        .catch(err => {
          console.log(err)
          root.$notify({
            title: '',
            message: 'ファイルのアップロードに失敗しました',
            type: 'error'
          })
        })
    }

    const clearFile = no => {
      switch (no) {
        case 1:
          attachFile1.value = ''
          uploadFileData1.value = ''
          fileRef1.value.value = null
          break
      }
    }

    return {
      loading: computed(() => root.$store.getters.loading),
      me,
      isSp,
      ID_CHECKED_STATUS,
      inputFile,
      showCameraMenu,
      showCameraNo,
      canPreviewFile,
      uploadFileData1,
      attachFile1,
      upload,
      canUpload,
      clearFile,
      fileRef1,
      isManager
    }
  }
})
</script>

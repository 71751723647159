<template src="./PlatinumSelect.html" />
<style lang="scss" scoped src="./PlatinumSelect.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import Camera from '@/compornents/Camera'
import { ID_CHECKED_STATUS } from '@/constants/constant'
import { canPreview, getExt } from '@/utils/common'
import { createImage, isPDF } from '@/utils/image'

export default defineComponent({
  name: 'PlatinumSelect',
  components: {
    HeaderRight,
    AvatarImg,
    Camera
  },
  title() {
    return 'ダウンロード用施設資料'
  },
  setup(_, { root }) {
    const { me } = useMe(root)
    const isSp = computed(() => root.$store.getters.isSp)

    const showCameraMenu2 = ref(false)
    const showCameraNo2 = ref(0)
    const attachFile2 = ref('')
    const uploadFileData2 = ref('')
    const fileRef2 = ref(null)
    const inputFile2 = e => {
      const files = e.target.files || e.dataTransfer.files
      if (!isPDF(getExt(files[0].name))) {
        root.$notify({
          title: '',
          message: 'PDFファイルを選択してください',
          type: 'error'
        })
        switch (showCameraNo2.value) {
          case 1:
            fileRef2.value.value = null
            break
        }
        return
      }
      showCameraMenu2.value = false
      switch (showCameraNo2.value) {
        case 1:
          attachFile2.value = files[0]
          createImage(attachFile2.value, uploadFileData2)
          break
      }
    }
    const canPreviewFile2 = no => {
      switch (no) {
        case 1:
          return attachFile2.value ? canPreview(attachFile2.value.name) : false
      }
    }

    const canUpload2 = computed(() => uploadFileData2.value)

    const clearFile2 = no => {
      switch (no) {
        case 1:
          attachFile2.value = ''
          uploadFileData2.value = ''
          fileRef2.value.value = null
          break
      }
    }

    return {
      loading: computed(() => root.$store.getters.loading),
      me,
      isSp,
      ID_CHECKED_STATUS,
      inputFile2,
      showCameraMenu2,
      showCameraNo2,
      canPreviewFile2,
      uploadFileData2,
      attachFile2,
      canUpload2,
      clearFile2,
      fileRef2
    }
  }
})
</script>

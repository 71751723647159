<template src="./FamilyRegister.html" />
<style lang="scss" scoped src="./FamilyRegister.scss"></style>
<script>
import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch
} from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import {
  GENDER_LIST,
  PREFECTURE_LIST,
  USER_CATEGORY,
  USER_CATEGORY_NAMES,
  SUB_CATEGORY,
  COPY_RIGHT
} from '@/constants/constant'
import {
  GET_POST_CODE,
  GET_USER_SEARCH,
  POST_USER_PROFILE
} from '@/store/types'
import { getThisYear } from '@/utils/date'
import '@/comporsables/useVeeValidate'
import VueRecaptcha from 'vue-recaptcha'

export default defineComponent({
  name: 'FamilyRegister',
  title() {
    return 'ご本人、ご家族新規会員登録'
  },
  components: {
    VueRecaptcha
  },
  setup(_, { root }) {
    const recaptchaSiteKey = ref(process.env.VUE_APP_RECAPTCHA_SITE_KEY_V2)
    const { me } = useMe(root)
    if (!me.is_login) {
      root.$router.push({ name: 'login' })
    }
    if (me.last_name) {
      root.$router.push({ name: 'home' })
    }
    if (parseInt(me.is_staff) != 1) {
      root.$router.push({ name: 'home' })
    }

    const step = computed(() => parseInt(root.$route.params.step))
    const form = reactive({
      category_id: 'family',
      last_name: '',
      last_name_kana: '',
      first_name: '',
      first_name_kana: '',
      min_care_id: '',
      email: me.email,
      gender_id: 'male',
      year: '1970',
      month: '01',
      day: '01',
      position: '',
      qualification: '',
      organization_name: '',
      postcode: '',
      prefecture_id: '',
      city: '',
      block: '',
      building: '',
      home_phone: '',
      mobile_phone: '',
      agreement: false
    })
    if (step.value > 1 && form.category_id === '') {
      root.$router.push({ name: 'family_register', params: { step: 1 } })
    }

    const selectCategory = category_id => {
      form.category_id = category_id
      form.sub_category_id = ''
      form.other_sub_category_name = ''
    }

    if (me.office_number) {
      form.office_number = me.office_number
    }

    const showSubCategoryError = ref(false)
    const clickNext = next_step => {
      if (
        next_step === 3 &&
        [USER_CATEGORY.BUSINESS, USER_CATEGORY.MEDICAL].includes(
          form.category_id
        ) &&
        form.sub_category_id === ''
      ) {
        showSubCategoryError.value = true
        return
      }

      root.$router.push({
        name: 'family_register',
        params: { step: next_step }
      })
    }

    const clickPrev = () => {
      if (step.value === 1) {
        return
      }
      root.$router.push({
        name: 'family_register',
        params: { step: step.value - 1 }
      })
    }

    const categoryName = computed(() => USER_CATEGORY_NAMES[form.category_id])

    const genderName = computed(
      () => GENDER_LIST.find(g => g.value === form.gender_id).label
    )

    const postProfile = () => {
      let param = {
        postcode: form.postcode,
        prefecture_id: form.prefecture_id,
        city: form.city,
        block: form.block,
        building: form.building,
        birthday: `${form.year}-${form.month}-${form.day}`,
        category_id: form.category_id,
        first_name: form.first_name,
        first_name_kana: form.first_name_kana,
        gender_id: form.gender_id,
        last_name: form.last_name,
        last_name_kana: form.last_name_kana,
        min_care_id: form.min_care_id,
        organization_name: form.organization_name,
        position: form.position,
        qualification: form.qualification,
        home_phone: form.home_phone,
        mobile_phone: form.mobile_phone,
        recaptchaToken: recaptchaToken
      }
      root.$store
        .dispatch(POST_USER_PROFILE, param)
        .then(() => location.reload())
        .catch(() => undefined)
    }

    const thisYear = getThisYear()
    let years = []
    for (let i = 1950; i <= thisYear; i++) {
      years = [...years, i]
    }

    const minCareIdValid = ref('')

    watch(
      () => form.min_care_id,
      v => {
        if (v.length < 6) {
          return
        }
        minCareIdValid.value = ''
        root.$store
          .dispatch(GET_USER_SEARCH, form.min_care_id)
          .then(() => {
            minCareIdValid.value = root.$store.getters.user ? 'NG' : 'OK'
          })
          .catch(() => {
            minCareIdValid.value = 'NG'
          })
      }
    )

    const recaptchaToken = ref('')
    const onVerify = response => {
      if (response !== '') {
        recaptchaToken.value = response
      }
    }

    // const recaptcha = ref(null)
    // onMounted(() => {
    //   console.log(recaptcha.value)
    // })

    const onExpired = () => {
      // console.log(recaptcha.value.reset())
      recaptchaToken.value = ''
    }

    const postCodeIsInvalid = ref(false)
    const selectedPrefecture = computed(() =>
      form.prefecture_id
        ? PREFECTURE_LIST.find(p => p.value === parseInt(form.prefecture_id))
            .label
        : ''
    )

    watch(
      () => form.postcode,
      v =>
        root.$store
          .dispatch(GET_POST_CODE, v)
          .then(() => {
            postCodeIsInvalid.value = false
            form.prefecture_id = root.$store.getters.address.prefecture_id
            form.city = root.$store.getters.address.city
            form.block = root.$store.getters.address.block
          })
          .catch(() => {
            postCodeIsInvalid.value = true
          })
    )

    const loading = computed(() => root.$store.getters.loading)

    return {
      loading,
      me,
      USER_CATEGORY,
      GENDER_LIST,
      PREFECTURE_LIST,
      selectCategory,
      recaptchaSiteKey,
      step,
      form,
      categoryName,
      genderName,
      years,
      minCareIdValid,
      recaptchaToken,
      clickNext,
      clickPrev,
      postProfile,
      onVerify,
      onExpired,
      postCodeIsInvalid,
      selectedPrefecture,
      SUB_CATEGORY,
      COPY_RIGHT,
      showSubCategoryError
    }
  }
})
</script>

import {
  CLEAR_REPORT_MESSAGES,
  SET_MAKE_REPORT_MODE,
  SET_REPORT_MESSAGES
} from './types'

export default {
  state: {
    makeReportMode: false,
    reportMessages: []
  },
  mutations: {
    [SET_MAKE_REPORT_MODE](state, payload) {
      state.makeReportMode = payload
    },
    [SET_REPORT_MESSAGES](state, message) {
      if (state.reportMessages.find(m => m.message_id === message.message_id)) {
        state.reportMessages = state.reportMessages.filter(
          m => m.message_id !== message.message_id
        )
      } else {
        state.reportMessages = [...state.reportMessages, message]
      }

      const compare = (a, b) => {
        let comparison = 0
        if (a.message_id > b.message_id) {
          comparison = 1
        } else if (a.message_id < b.message_id) {
          comparison = -1
        }
        return comparison
      }
      state.reportMessages = state.reportMessages.sort(compare)
    },
    [CLEAR_REPORT_MESSAGES](state) {
      state.reportMessages = []
    }
  },
  getters: {
    makeReportMode: state => state.makeReportMode,
    reportMessages: state => state.reportMessages
  },
  actions: {
    [SET_MAKE_REPORT_MODE]({ commit }, payload) {
      commit(SET_MAKE_REPORT_MODE, payload)
    },
    [SET_REPORT_MESSAGES]({ commit }, message) {
      commit(SET_REPORT_MESSAGES, message)
    },
    [CLEAR_REPORT_MESSAGES]({ commit }) {
      commit(CLEAR_REPORT_MESSAGES)
    }
  }
}

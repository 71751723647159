import * as http from '@/utils/api'
import { GET_KAIGO_KOUHYOU } from './types'
import { ENDPOINT_KAIGO_KOUHYOU } from '@/constants/endpoint'
import { isEmptyObject } from '@/utils/common'

export default {
  state: {
    kaigo_kouhyou: null
  },
  mutations: {
    [GET_KAIGO_KOUHYOU](state, responseData) {
      if (!isEmptyObject(responseData)) {
        state.kaigo_kouhyou = responseData
      }
    }
  },
  getters: {
    kaigo_kouhyou: state => state.kaigo_kouhyou
  },
  actions: {
    [GET_KAIGO_KOUHYOU]: async ({ commit, dispatch }, officeNumber) => {
      try {
        const response = await http.get(ENDPOINT_KAIGO_KOUHYOU, {
          office_number: officeNumber
        })
        commit(GET_KAIGO_KOUHYOU, response.data)
      } catch (error) {
        console.error('Error in GET_KAIGO_KOUHYOU action:', error)
        http.handleError(dispatch, error)
      }
    }
  }
}

import axios from 'axios'
import { LOADING_TYPE_NONE, LOADING_TYPE_PAGE } from '@/constants/constant'
import store from '@/store'
import router from '@/router'
import { SET_LOADING, SET_MAINTENANCE_MESSAGE } from '@/store/types'
import { Loading, MessageBox, Notification } from 'element-ui'

/**
 * GET
 * @param endpoint
 * @param params
 * @param loadingType
 * @returns {AxiosPromise}
 */
export function get(endpoint, params, loadingType = LOADING_TYPE_NONE) {
  return create(endpoint, loadingType).get(endpoint, { params: params })
}

/**
 * GET ダウンロード用
 * @param endpoint
 * @param params
 * @param loadingType
 * @returns {AxiosPromise}
 */
export function getArrayBuffer(
  endpoint,
  params,
  loadingType = LOADING_TYPE_NONE
) {
  return create(endpoint, loadingType).get(endpoint, {
    params: params,
    responseType: 'arraybuffer'
  })
}

/**
 * DELETE
 * @param endpoint
 * @param params
 * @param loadingType
 * @returns {AxiosPromise}
 */
export function del(endpoint, params, loadingType = LOADING_TYPE_NONE) {
  return create(endpoint, loadingType).delete(endpoint, params)
}

/**
 * PUT
 * @param endpoint
 * @param params
 * @param loadingType
 * @returns {AxiosPromise}
 */
export function put(endpoint, params, loadingType = LOADING_TYPE_NONE) {
  return create(endpoint, loadingType).put(endpoint, params)
}

/**
 * POST
 * @param endpoint
 * @param params
 * @param loadingType
 * @returns {AxiosPromise}
 */
export function post(endpoint, params, loadingType = LOADING_TYPE_NONE) {
  return create(endpoint, loadingType).post(endpoint, params)
}

/**
 * PATCH
 * @param endpoint
 * @param params
 * @param loadingType
 * @returns {AxiosPromise}
 */
export function patch(endpoint, params, loadingType = LOADING_TYPE_NONE) {
  return create(endpoint, loadingType).patch(endpoint, params)
}

/**
 * post（multipart/form-data）
 * @param endpoint
 * @param params
 * @param loadingType
 * @returns {AxiosPromise}
 */
export function postMultiPartFormData(endpoint, params, loadingType = null) {
  const formData = new window.FormData()
  for (const [key, value] of Object.entries(params)) {
    formData.append(key, value)
  }
  const config = { headers: { 'content-type': 'multipart/form-data' } }
  return create(endpoint, loadingType).post(endpoint, formData, config)
}

/**
 * axiosインスタンス生成
 * @param endpoint
 * @param loadingType
 * @returns {AxiosInstance}
 */
function create(endpoint, loadingType) {
  const instance = axios.create()
  const headers = {
    'If-Modified-Since': 'Thu, 01 Jun 1970 00:00:00 GMT'
  }
  instance.defaults.headers.common = {
    ...instance.defaults.headers.common,
    ...headers
  }
  instance.defaults.withCredentials = true

  // ローディング画像を表示
  return setLoading(instance, loadingType)
}

/**
 * ローディング画像表示
 * @param instance
 * @param loadingType
 * @returns {*}
 */
function setLoading(instance, loadingType) {
  let loading = null

  // リクエスト送信前
  instance.interceptors.request.use(config => {
    if (loadingType === LOADING_TYPE_PAGE) {
      loading = Loading.service({ fullscreen: true })
    } else {
      store.commit(SET_LOADING, { type: loadingType, status: true })
    }

    return config
  })

  instance.interceptors.response.use(
    // レスポンス取得後
    response => {
      // ローディング画像を非表示
      if (loadingType === LOADING_TYPE_PAGE) {
        loading.close()
      } else {
        store.commit(SET_LOADING, { type: loadingType, status: false })
      }
      return response
    },
    // エラー発生時
    error => {
      if (loadingType === LOADING_TYPE_PAGE) {
        loading.close()
      } else {
        store.commit(SET_LOADING, { type: loadingType, status: false })
      }
      return Promise.reject(error)
    }
  )

  return instance
}

/**
 * エラーハンドリング
 * @param dispatch
 * @param error
 * @returns {*}
 */
export function handleError(dispatch, error) {
  // ローディング画像を非表示
  store.commit(SET_LOADING, { type: LOADING_TYPE_PAGE, status: false })

  if (error.response) {
    console.log(error.response.data)
  }

  let errors = []
  let messages = []
  let duration = 4500
  if (error.response && error.response.data) {
    let response_status = 0
    if (error.response.status) {
      response_status = error.response.status
      console.log('response_statusパターン1', response_status)
      console.log('response data', error.response.data)
    }
    if (error.response.data.status) {
      response_status = error.response.data.status
      console.log('response_statusパターン2', response_status)
      console.log('response data', error.response.data)
    }

    switch (response_status) {
      case 401:
        if ('/login' !== location.pathname) {
          console.log('reload by 401')
          router.push({ name: 'login' }).then(() => location.reload())
        }
        break
      case 422:
      case 400:
      case 403:
        messages = error.response.data.messages
        if (Array.isArray(error.response.data)) {
          duration = 10000
          error.response.data.map(d => {
            errors = [...errors, d[Object.keys(d)]]
          })
        } else {
          errors =
            typeof error.response.data.errors === 'string'
              ? [error.response.data.errors]
              : error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                ]
        }
        break
      case 404:
        errors = ['404 Not Found']
        break
      case 503:
        dispatch(SET_MAINTENANCE_MESSAGE, error.response.data.messages)
        break
      case 429:
        break
      default:
        console.log(error.response)
        //errors = ['システムエラーが発生しました']
        break
    }
  } else if (error.request) {
    // レスポンス無しの場合
    console.log(error.request)
    //errors = ['システムエラーが発生しました', 'サーバーから応答がありません']
  } else {
    // リクエスト処理のエラー
    //errors = ['システムエラーが発生しました。']
  }

  if (errors && errors.length > 0) {
    Notification({
      showClose: true,
      message: '<div>' + errors.join('<br>') + '</div>',
      dangerouslyUseHTMLString: true,
      type: 'error',
      duration: duration
    })
    return
  }

  if (messages && messages.length > 0) {
    MessageBox({
      confirmButtonClass: 'confirmButton',
      confirmButtonText: 'OK',
      message: messages.join('<br>'),
      dangerouslyUseHTMLString: true,
      type: 'warning',
      center: true
    })
  }
}

export function handle401() {
  if ('/login' !== location.pathname) {
    router.push({ name: 'login' })
  }
}

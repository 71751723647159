<template>
  <div class="login-right">
    <div class="inner">
      <template v-if="!isSp">
        <h2 class="__title">動画でみんなでケアの使い方を知ろう</h2>
        <div class="__video text-center">
          <div class="youtube">
            <iframe
              src="https://www.youtube.com/embed/xjSY07QX1sE"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </template>

      <h2 class="__title">みんなでケアが目指すこと</h2>
      <p class="__txt">
        みんなでケアはこれまでの大変だった介護の事務作業をもっとかんたんにもっと円滑にして介護業務の効率化をサポートし、ケアマネさん介護事業者さんその他大勢の皆さんとご利用者様が「みんなで」記憶に残り続けていただけるような時間を創り出すことに貢献します。<br />
        みんなでケアは日常生活に馴染みのあるオンラインチャットシステムを中心としたICT技術を用いることで、限りある紙資源の無駄使いをなくし、地球環境にやさしい介護をご提供いたします。<br />
        みんなでケアは「記憶と記録に残るケアをみんなで」をスローガンとして、未だに遠い介護のICT化を大きく促進させ、本当の意味での多職種連携を新たな形で実現します。
      </p>

      <ul class="__list-btn">
        <li v-if="false">
          <a
            href="https://service.minna-de-care.com/about/"
            title="みんなでケアとは"
            target="_blank"
            >みんなでケアとは</a
          >
        </li>
        <li>
          <a
            href="https://service.minna-de-care.com/terms/"
            title="利用規約"
            target="_blank"
            >利用規約</a
          >
        </li>
        <li>
          <a
            href="https://service.minna-de-care.com/privacy/"
            title="プライバシーポリシー"
            target="_blank"
            >プライバシーポリシー</a
          >
        </li>
        <li>
          <a
            href="https://w-wonderfullife.com/"
            title="運営会社"
            target="_blank"
            >運営会社</a
          >
        </li>
        <li v-if="false">
          <a
            href="https://service.minna-de-care.com/faq/"
            title="よくある質問"
            target="_blank"
            >よくある質問</a
          >
        </li>
        <li>
          <a
            href="https://service.minna-de-care.com/contact/"
            title="お問い合わせ"
            target="_blank"
            >お問い合わせ</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.youtube {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 560px;
}

.youtube::before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 56.25%;
}

.youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.login-right {
  .__list-btn {
    li {
      width: 50%;
    }
  }
}
</style>

<script>
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'LoginRight',
  setup(_, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    return {
      isSp
    }
  }
})
</script>

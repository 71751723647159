import { APP_DOMAIN, ENV_DEV, ENV_LOCAL, ENV_PRD } from '@/constants/constant'

export function getEnv() {
  const hostname = window.location.hostname
  if (hostname === APP_DOMAIN) {
    return ENV_PRD
  }

  const subDomain = hostname.match(/^[^.]+/)[0]
  if (subDomain === 'localhost' || subDomain === '192') {
    return ENV_LOCAL
  } else {
    // dev, stg
    return subDomain
  }
}

export function isLocal() {
  return getEnv() === ENV_LOCAL
}

export function isPrd() {
  return getEnv() === ENV_PRD
}

export function getEnvName() {
  let envName = ''
  switch (getEnv()) {
    case ENV_PRD:
      envName = ''
      break
    case ENV_DEV:
      envName = 'DEV'
      break
    default:
      envName = 'Local'
      break
  }
  return envName
}

export function uniq(array) {
  return Array.from(new Set(array))
}

export function isEmptyObject(o) {
  return !Object.keys(o).length
}

export function getExt(filename) {
  if (!filename) {
    return ''
  }
  var pos = filename.lastIndexOf('.')
  if (pos === -1) return ''
  return filename.slice(pos + 1)
}

export function canPreview(filename) {
  return ['png', 'jpg', 'jpeg', 'svg', 'gif'].includes(
    getExt(filename).toLowerCase()
  )
}

export function iconExists(ext) {
  return [
    'after-effects',
    'ai',
    'audition',
    'avi',
    'bridge',
    'css',
    'csv',
    'dbf',
    'doc',
    'dreamweaver',
    'dwg',
    'exe',
    'file',
    'fireworks',
    'fla',
    'flash',
    'html',
    'illustrator',
    'indesign',
    'iso',
    'javascript',
    'jpg',
    'json-file',
    'mp3',
    'mp4',
    'pdf',
    'photoshop',
    'png',
    'ppt',
    'prelude',
    'premiere',
    'psd',
    'rtf',
    'search',
    'svg',
    'txt',
    'xls',
    'xml',
    'zip',
    'zip-1'
  ].includes(ext)
}

export function downloadFile(url, filename) {
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url, true)
  xhr.responseType = 'blob'
  xhr.onload = () => {
    const blob = xhr.response
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename)
    } else {
      const objectURL = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      document.body.appendChild(link)
      link.href = objectURL
      link.download = filename
      link.click()
      document.body.removeChild(link)
    }
  }
  xhr.send()
}

export function replace_all(string, from, to) {
  return string.replace(new RegExp(from, 'g'), to)
}

export function prepareApp(userId) {
  if (isPrd()) {
    return
  }

  console.log('prepareApp', userId)
  if ('webkit' in window) {
    window.webkit.messageHandlers.prepare.postMessage(
      JSON.stringify({
        user_id: userId
      })
    )
  } else if ('android' in window) {
    window.android.prepare(userId)
  }
}

export const isChrome = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()

  if (userAgent.indexOf('msie') !== -1) {
    return false
  } else if (userAgent.indexOf('edge') !== -1) {
    return false
  } else if (userAgent.indexOf('android') !== -1) {
    return false
  } else if (userAgent.indexOf('chrome') !== -1) {
    return true
  } else if (userAgent.indexOf('safari') !== -1) {
    return false
  } else if (userAgent.indexOf('firefox') !== -1) {
    return false
  }
  return false
}

export async function sha256(text) {
  const uint8 = new TextEncoder().encode(text)
  const digest = await crypto.subtle.digest('SHA-256', uint8)
  return Array.from(new Uint8Array(digest))
    .map(v => v.toString(16).padStart(2, '0'))
    .join('')
}

import { SET_LOADING } from './types'
import { LOADING_TYPE_NONE } from '@/constants/constant'

export default {
  state: {
    page: false,
    login: false,
    logout: false,
    chat: false,
    register_chat: false,
    delete_chat: false,
    contact: false,
    add_contact: false,
    individual_or_group_message: false,
    family_message: false,
    records: false,
    attach: false,
    s3_url: false,
    schedules: false,
    next_schedules: false,
    schedule: false,
    user_search: false,
    files: false,
    reset_password: false,
    business_profile: false,
    put_chat_owner: false,
    post_staff: false,
    invited_staffs: false,
    staffs: false,
    staff_chats: false,
    staff_histories: false,
    staff_monthly_usage: false,
    staff_monthly_usage_detail: false
  },
  mutations: {
    [SET_LOADING](state, payload) {
      state[payload.type] = payload.status
    }
  },
  getters: {
    loading: state => state
  },
  actions: {
    [SET_LOADING]({ commit }, payload) {
      if (payload.type !== LOADING_TYPE_NONE) {
        commit(SET_LOADING, payload)
      }
    }
  }
}

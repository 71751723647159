<template src="./Files.html" />
<style lang="scss" scoped src="./Files.scss"></style>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import FileList from '@/compornents/FileList/FileList'

export default defineComponent({
  name: 'Files',
  components: {
    FileList
  },
  title() {
    return 'ファイル管理'
  },
  setup(_, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)

    return {
      isSp
    }
  }
})
</script>

<template src="./PlatinumConditions.html" />
<style lang="scss" scoped src="./PlatinumConditions.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import ProfileModal from '@/compornents/ProfileModal/ProfileModal'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import { getWindowHeight } from '@/utils/window'

export default defineComponent({
  name: 'PlatinumConditions',
  title() {
    return '利用規約'
  },
  components: {
    HeaderRight,
    ProfileModal,
    AvatarImg
  },
  setup(_, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const { me } = useMe(root)
    const agreementCheckbox = ref(false)
    const isError = ref(false)
    const isAgreed = computed(() => agreementCheckbox.value)
    const returnPage = () => {
      root.$router.push({
        name: 'platinum_page',
        params: { layer1: 'platinum' }
      })
    }

    return {
      isSp,
      me,
      isAgreed,
      isError,
      agreementCheckbox,
      returnPage,
      windowHeight: getWindowHeight(74)
    }
  }
})
</script>

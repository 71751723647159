<template src="./ResetPassword.html" />
<style lang="scss" scoped src="./ResetPassword.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { PATCH_FORGOT_PASSWORD } from '@/store/types'
import '@/comporsables/useVeeValidate'
import { COPY_RIGHT } from '@/constants/constant'

export default defineComponent({
  name: 'ResetPassword',
  title() {
    return 'パスワードの再発行'
  },
  setup(_, { root }) {
    if (root.$store.getters.me && root.$store.getters.me.is_login) {
      root.$router.push({ name: 'home' })
    }

    const token = computed(() => root.$route.params.token)
    const email = computed(() => root.$route.params.email)
    const password = ref('')
    const sended = ref(false)

    const sendForm = () => {
      root.$store
        .dispatch(PATCH_FORGOT_PASSWORD, {
          email: email.value,
          password: password.value,
          token: token.value
        })
        .then(() => (sended.value = true))
    }

    return {
      token,
      email,
      password,
      sendForm,
      sended,
      COPY_RIGHT
    }
  }
})
</script>

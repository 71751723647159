<template src="./FileList.html" />
<style lang="scss" scoped src="./FileList.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { EXT_FILES, LOADING_TYPE_S3_URL, TAG_ID } from '@/constants/constant'
import {
  CLEAR_FILE_SEARCH_WORD,
  DELETE_FILE,
  FETCH_FILES,
  INPUT_FILE_SEARCH_WORD,
  SET_LOADING
} from '@/store/types'
import { canPreview, downloadFile, getExt, iconExists } from '@/utils/common'
import { getS3Url } from '@/comporsables/useAws'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import { getWindowHeight } from '@/utils/window'

export default defineComponent({
  name: 'FileList',
  components: {
    HeaderRight
  },
  props: {
    chat: {
      type: Object,
      default: null
    },
    messageType: {
      type: String,
      default: null
    },
    selectedTagId: {
      type: Number,
      default: null
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const selectedTagName = computed(() =>
      props.selectedTagId === null
        ? '共有ファイル'
        : props.selectedTagId === 0
        ? 'チャット内の全てのファイル'
        : Object.values(TAG_ID).find(t => t.value === props.selectedTagId).label
    )

    const filesPage = ref(1)
    const selectedFile = ref(null)
    const selectedFileExt = ref(null)
    const tagId = ref('0')
    tagId.value = props.selectedTagId ? props.selectedTagId : '0'
    const selectTag = e => {
      tagId.value = e.target.value
      fetchFiles()
    }

    selectedFileExt.value =
      selectedFile.value && EXT_FILES.includes(selectedFile.value.ext)
        ? selectedFile.value.ext
        : 'file'
    const fetchFiles = () => {
      root.$store
        .dispatch(FETCH_FILES, {
          chat_id: props.chat ? props.chat.chat_id : null,
          message_type: props.messageType ? props.messageType : null,
          tag_id: tagId.value,
          page: filesPage.value
        })
        .then(() => {
          if (files.value.length > 0) {
            selectFile(files.value[0])
          }
        })
    }
    fetchFiles()

    const files = computed(() =>
      root.$store.getters.files.map(f => ({
        ...f,
        ext: getExt(f.file_name),
        tag_name: Object.values(TAG_ID).find(t => t.value === f.tag_id).label
      }))
    )
    const fileTotalPage = computed(() => root.$store.getters.file_total_page)

    const previewUrl = ref('')
    const setPreviewUrl = file => {
      if (canPreview(file.file_name)) {
        root.$store.dispatch(SET_LOADING, {
          type: LOADING_TYPE_S3_URL,
          status: true
        })
        getS3Url(file.path).then(url => {
          root.$store.dispatch(SET_LOADING, {
            type: LOADING_TYPE_S3_URL,
            status: false
          })
          previewUrl.value = url
        })
      } else {
        previewUrl.value = ''
      }
    }
    const loading = computed(() => root.$store.getters.loading)

    const selectFile = f => {
      selectedFile.value = f
      setPreviewUrl(f)
    }

    const showPreviewDialog = ref(false)
    const preview = f => {
      selectedFile.value = f
      setPreviewUrl(f)
      showPreviewDialog.value = true
    }

    const download = () => {
      getS3Url(selectedFile.value.path).then(url =>
        downloadFile(url, selectedFile.value.file_name)
      )
    }
    const downloadTarget = file => {
      getS3Url(file.path).then(url => downloadFile(url, file.file_name))
    }

    const nextPage = () => {
      if (filesPage.value === fileTotalPage.value) {
        return
      }
      filesPage.value++
      fetchFiles()
    }

    const prevPage = () => {
      if (filesPage.value === 1) {
        return
      }
      filesPage.value--
      fetchFiles()
    }

    const deleteFileId = ref(0)
    const deleteFileConfirm = id => {
      deleteFileId.value = id
    }
    const deleteFile = () => {
      root.$store.dispatch(DELETE_FILE, { id: deleteFileId.value }).then(() => {
        deleteFileId.value = 0
        fetchFiles()
      })
      if (selectedFile.value && selectedFile.value.id === deleteFileId.value) {
        selectedFile.value = null
      }
    }

    const fileSearchWord = computed(() => root.$store.getters.fileSearchWord)
    const inputFileSearchWord = e => {
      root.$store.dispatch(INPUT_FILE_SEARCH_WORD, e.target.value)
    }
    const clearFileSearchWord = () => {
      root.$store.dispatch(CLEAR_FILE_SEARCH_WORD)
    }

    return {
      isSp,
      selectedTagName,
      selectedFile,
      selectedFileExt,
      selectFile,
      files,
      filesPage,
      preview,
      canPreview,
      showPreviewDialog,
      previewUrl,
      fileTotalPage,
      nextPage,
      prevPage,
      download,
      downloadTarget,
      deleteFileConfirm,
      fileSearchWord,
      inputFileSearchWord,
      clearFileSearchWord,
      deleteFileId,
      deleteFile,
      loading,
      TAG_ID,
      tagId,
      iconExists,
      selectTag,
      windowHeight: getWindowHeight(195)
    }
  }
})
</script>

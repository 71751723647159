import AWS from 'aws-sdk'

AWS.config.region = 'ap-northeast-1'
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'ap-northeast-1:7e527dbf-6f8e-4d7f-aa88-ac4a3166bd1c'
})

const bucketNameAttachment = process.env.VUE_APP_BUCKET_NAME_ATTACHMENT
const bucketNamePublic = process.env.VUE_APP_BUCKET_NAME_PUBLIC
const s3 = new AWS.S3({ apiVersion: '2006-03-01' })

AWS.config.getCredentials(err => {
  if (err) {
    console.log(err.stack)
  }
})

export function putS3Base(key, body, contentType, bucketName) {
  var params = {
    Bucket: bucketName,
    Key: key,
    Body: body,
    ContentType: contentType
  }

  return new Promise((resolve, reject) => {
    s3.putObject(params, err => {
      if (err) {
        reject(err)
      } else {
        console.log('Successfully uploaded data to ' + bucketName + '/' + key)
        resolve()
      }
    })
  })
}

export function putS3Attachment(key, body, contentType) {
  return putS3Base(key, body, contentType, bucketNameAttachment)
}

export function putS3Public(key, body, contentType) {
  return putS3Base(key, body, contentType, bucketNamePublic)
}

export function getS3Url(key) {
  const params = {
    Bucket: bucketNameAttachment,
    Key: key,
    Expires: 100 // 秒
  }

  return new Promise((resolve, reject) => {
    s3.getSignedUrl('getObject', params, (err, url) => {
      if (err) {
        reject(err)
      } else {
        resolve(url)
      }
    })
  })
}

<template src="./Owner.html" />
<style lang="scss" scoped src="./Owner.scss"></style>
<script>
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import { POST_OWNER } from '@/store/types'
import LoginRight from '@/compornents/LoginRight'
import '@/comporsables/useVeeValidate'
import { COPY_RIGHT } from '@/constants/constant'

export default defineComponent({
  name: 'Owner',
  title() {
    return '事業者新規登録'
  },
  components: {
    LoginRight
  },
  setup(_, { root }) {
    if (root.$store.getters.me && root.$store.getters.me.is_login) {
      root.$router.push({ name: 'home' })
    }

    const isSp = computed(() => root.$store.getters.isSp)

    // 会員登録
    const registerForm = reactive({
      email: '',
      password: ''
    })

    const isRegisterError = ref(false)
    const register = () => {
      root.$store
        .dispatch(POST_OWNER, registerForm)
        .then(() => {
          root.$router.push({ name: 'user_registered' })
        })
        .catch(() => {
          isRegisterError.value = true
        })
    }

    const showPopup = ref(false)
    const handleYesClick = () => {
      showPopup.value = false
    }
    const handleNoClick = () => {
      root.$router.push({ name: 'login' })
    }

    root.$nextTick(() => {
      showPopup.value = true
    })

    const loading = computed(() => root.$store.getters.loading)

    return {
      loading,
      isSp,
      register,
      registerForm,
      isRegisterError,
      COPY_RIGHT,
      showPopup,
      handleYesClick,
      handleNoClick
    }
  }
})
</script>

<template src="./BusinessProfileRegister.html" />
<style lang="scss" scoped src="./BusinessProfileRegister.scss"></style>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import BusinessProfile from '@/compornents/BusinessProfile/BusinessProfile'
import { FETCH_BUSINESS_PROFILE, FETCH_ME } from '@/store/types'

export default defineComponent({
  name: 'BusinessProfileRegister',
  title() {
    return '決済代行情報登録'
  },
  components: {
    BusinessProfile
  },
  setup(_, { root }) {
    const { me } = useMe(root)
    if (!me.is_login) {
      root.$router.push({ name: 'login' })
    }

    Promise.all([
      root.$store.dispatch(FETCH_ME),
      root.$store.dispatch(FETCH_BUSINESS_PROFILE)
    ])
      .then(() => {
        const isManage = computed(() => root.$store.getters.isManage)
        const businessProfile = root.$store.getters.business_profile

        if (!me.is_login) {
          root.$router.push({ name: 'login' })
        } else if (!isManage.value || businessProfile) {
          root.$router.push({ name: 'home' })
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error)
        root.$router.push({ name: 'login' })
      })

    return {}
  }
})
</script>

import { render, staticRenderFns } from "./AvatarImg.html?vue&type=template&id=7c85b535&scoped=true&"
import script from "./AvatarImg.vue?vue&type=script&lang=js&"
export * from "./AvatarImg.vue?vue&type=script&lang=js&"
import style0 from "./AvatarImg.scss?vue&type=style&index=0&id=7c85b535&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c85b535",
  null
  
)

export default component.exports
<template>
  <div class="login scrollbar">
    <div class="wrapper">
      <div class="login-left">
        <div class="inner">
          <h1 class="__logo">
            <a href="/" title="みんなでケア">
              <img src="@/assets/images/logo.svg" alt="みんなでケア" />
            </a>
          </h1>
          <div id="login-tabs" class="login-tabs">
            <ul class="tabs-list">
              <li>
                <router-link :to="{ name: 'login' }" title="ログイン">
                  ログイン
                </router-link>
              </li>
              <li class="current">
                <a href="#__tab2" title="新規会員登録">新規会員登録</a>
              </li>
            </ul>
            <div class="tabs-content">
              <div id="__tab2" class="tab-block show">
                <form action="" class="login-form">
                  <div class="form-error">
                    <p class="__pink normal">
                      ご入力いただいたメールアドレスにプロフィール登録のご案内をお送りしました。
                    </p>
                    <p class="__pink normal">
                      24時間以内にご登録をお願い致します。
                    </p>
                    <p>下記の件名をご確認ください。</p>
                    <p class="bold">
                      件名｜【みんなでケア】アカウント登録のご案内
                    </p>
                  </div>

                  <div class="inner">
                    <router-link :to="{ name: 'login' }" class="bnt__submit"
                      ><span class="icon-p-undo"></span>
                      ログイン画面に戻る</router-link
                    >
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginRight />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.bnt__submit {
  display: block;
  width: 320px;
  margin: 50px auto 0;
  background: #d8fbff;
  color: #00bcd4;
  font-size: 20px;
  line-height: 36px;
  border: none;
  padding: 14px;
  font-weight: bold;
  transition: all 0.3s;
  letter-spacing: 2px;
  border-radius: 6px;
  outline: none;
  text-align: center;
  @media only screen and (max-width: 992px) {
    width: 240px;
    font-size: 16px;
  }
}

.bnt__submit:hover {
  background-color: #00bcd4;
  color: #fff;
  text-decoration: none;
}
</style>
<script>
import { defineComponent } from '@vue/composition-api'
import LoginRight from '@/compornents/LoginRight'

export default defineComponent({
  name: 'UserRegistered',
  components: {
    LoginRight
  },
  title() {
    return 'ユーザー登録完了'
  }
})
</script>

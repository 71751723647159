import * as http from '@/utils/api'
import { POST_SAMPLE } from './types'
import { ENDPOINT_SAMPLE } from '@/constants/endpoint'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    [POST_SAMPLE]: async ({ dispatch }, params) => {
      await http.post(ENDPOINT_SAMPLE, params).catch(error => {
        http.handleError(dispatch, error)
        throw new Error()
      })
    }
  }
}

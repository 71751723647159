import * as http from '@/utils/api'
import {
  CLEAR_CONTACT_SEARCH_WORD,
  DELETE_CONTACT,
  FETCH_CONTACTS,
  INPUT_CONTACT_SEARCH_WORD,
  POST_CONTACT
} from './types'
import { ENDPOINT_CONTACT, ENDPOINT_CONTACTS } from '@/constants/endpoint'
import {
  LOADING_TYPE_ADD_CONTACT,
  LOADING_TYPE_CONTACT
} from '@/constants/constant'

export default {
  state: {
    contacts: [],
    contactSearchWord: ''
  },
  mutations: {
    [FETCH_CONTACTS](state, responseData) {
      state.contacts = responseData
    },
    [INPUT_CONTACT_SEARCH_WORD](state, payload) {
      state.contactSearchWord = payload
    },
    [CLEAR_CONTACT_SEARCH_WORD](state) {
      state.contactSearchWord = ''
    }
  },
  getters: {
    contacts: state => {
      if (!state.contactSearchWord) {
        return state.contacts
      }

      return state.contacts.filter(
        u =>
          u.last_name.indexOf(state.contactSearchWord) !== -1 ||
          u.first_name.indexOf(state.contactSearchWord) !== -1 ||
          u.last_name_kana.indexOf(state.contactSearchWord) !== -1 ||
          u.first_name_kana.indexOf(state.contactSearchWord) !== -1 ||
          u.organization_name.indexOf(state.contactSearchWord) !== -1
      )
    },
    contactSearchWord: state => state.contactSearchWord
  },
  actions: {
    [FETCH_CONTACTS]: async ({ dispatch, commit }) => {
      await http
        .get(ENDPOINT_CONTACTS, {}, LOADING_TYPE_CONTACT)
        .then(response => {
          commit(FETCH_CONTACTS, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [POST_CONTACT]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_CONTACT, params, LOADING_TYPE_ADD_CONTACT)
        .catch(error => {
          console.log(error)
          http.handleError(dispatch, error)
        })
    },
    [DELETE_CONTACT]: async ({ dispatch }, params) => {
      await http.del(ENDPOINT_CONTACT, params).catch(error => {
        http.handleError(dispatch, error)
      })
    },
    [INPUT_CONTACT_SEARCH_WORD]({ commit }, payload) {
      commit(INPUT_CONTACT_SEARCH_WORD, payload)
    },
    [CLEAR_CONTACT_SEARCH_WORD]({ commit }) {
      commit(CLEAR_CONTACT_SEARCH_WORD)
    }
  }
}

<template src="./FileListModal.html" />
<style lang="scss" scoped src="./FileListModal.scss"></style>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import FileList from '@/compornents/FileList/FileList'

export default defineComponent({
  name: 'FileListModal',
  components: {
    FileList
  },
  props: {
    chat: {
      type: Object,
      require: true
    },
    messageType: {
      type: String,
      require: true
    },
    selectedTagId: {
      type: Number,
      require: true
    }
  },
  setup(props, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    return {
      isSp
    }
  }
})
</script>

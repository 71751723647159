<template src="./RecordModal.html" />
<style lang="scss" scoped src="./RecordModal.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { PRINT_STYLE_RECORD } from '@/constants/printStyle'
import { CLEAR_RECORDS, FETCH_RECORDS, POST_RECORD } from '@/store/types'
import { RECORD_TYPE } from '@/constants/constant'
import { getWindowHeight } from '@/utils/window'

export default defineComponent({
  name: 'RecordModal',
  props: {
    chatId: {
      type: Number,
      require: true
    },
    recordType: {
      type: String,
      require: true
    }
  },
  setup(props, { root, emit }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const title = computed(() =>
      props.recordType === RECORD_TYPE.EMERGENCY ? '緊急対応記録' : '入退院記録'
    )
    // https://stackoverflow.com/questions/52343006/how-to-print-a-part-of-a-vue-component-without-losing-the-style
    const print = () => {
      const prtHtml = document.getElementById('print-content').innerHTML

      let stylesHtml = PRINT_STYLE_RECORD
      const WinPrint = window.open('', '')

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    <title>${title.value} | Minna de care</title>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    }

    const inputMessage = ref('')
    const charHeight = isSp.value ? 20 : 18
    const messageHeight = computed(
      () =>
        (inputMessage.value.split('\n').length > 20
          ? 20
          : inputMessage.value.split('\n').length) *
          charHeight +
        30 +
        'px'
    )

    root.$store
      .dispatch(FETCH_RECORDS, {
        chat_id: props.chatId,
        record_type: props.recordType
      })
      .then(() => {
        scrollToBottom()
      })
    const records = computed(() => root.$store.getters.records)

    const loading = computed(() => root.$store.getters.loading)

    const closeModal = () => {
      root.$store.dispatch(CLEAR_RECORDS)
      emit('closeRecordModal')
    }

    const sendMessage = () => {
      if (inputMessage.value === '') {
        return
      }

      const postMessage = inputMessage.value
      inputMessage.value = ''

      root.$store
        .dispatch(POST_RECORD, {
          chat_id: props.chatId,
          record_type: props.recordType,
          contents: postMessage
        })
        .then(() => {
          root.$store
            .dispatch(FETCH_RECORDS, {
              chat_id: props.chatId,
              record_type: props.recordType
            })
            .then(() => {
              scrollToBottom()
            })
        })
    }

    const scrollToBottom = () => {
      root.$scrollTo('#bottom', 0, {
        container: '#print-content',
        force: true,
        offset: 0,
        x: false,
        y: true
      })
    }

    return {
      isSp,
      title,
      print,
      records,
      loading,
      closeModal,
      messageHeight,
      inputMessage,
      sendMessage,
      RECORD_TYPE,
      windowHeight: getWindowHeight(50)
    }
  }
})
</script>

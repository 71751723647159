<template src="./ProfileModal.html" />
<style lang="scss" scoped src="./ProfileModal.scss"></style>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import ProfileView from '@/compornents/ProfileView/ProfileView'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import { USER_CATEGORY } from '@/constants/constant'

export default defineComponent({
  name: 'ProfileModal',
  components: {
    ProfileView,
    AvatarImg
  },
  props: {
    user: {
      type: Object,
      require: true
    }
  },
  setup(_, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)

    return {
      isSp,
      USER_CATEGORY
    }
  }
})
</script>

import { render, staticRenderFns } from "./Files.html?vue&type=template&id=892fad90&scoped=true&"
import script from "./Files.vue?vue&type=script&lang=js&"
export * from "./Files.vue?vue&type=script&lang=js&"
import style0 from "./Files.scss?vue&type=style&index=0&id=892fad90&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "892fad90",
  null
  
)

export default component.exports
import * as PusherPushNotifications from '@pusher/push-notifications-web'
import { isPrd } from '@/utils/common'

export const stopBeams = () => {
  if (isPrd()) {
    return
  }

  const beamsClient = new PusherPushNotifications.Client({
    instanceId: process.env.VUE_APP_BEAMS_INSTANCE_ID
  })
  beamsClient.stop().catch(console.error)
}

export const authBeams = user_id => {
  if (isPrd()) {
    return
  }

  const beamsClient = new PusherPushNotifications.Client({
    instanceId: process.env.VUE_APP_BEAMS_INSTANCE_ID
  })

  beamsClient
    .start()
    .then(() => beamsClient.addDeviceInterest('user-' + user_id))
    .then(() => beamsClient.getDeviceInterests())
    .then(interests => console.log('Current interests:', interests))
    .catch(console.error)
}

<template src="./HeaderRight.html" />
<style lang="scss" scoped src="./HeaderRight.scss"></style>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import { TOGGLE_FAMILY_CHAT } from '@/store/types'
import { CHAT_TYPE, USER_CATEGORY } from '../../constants/constant'

export default defineComponent({
  name: 'HeaderRight',
  components: {
    AvatarImg
  },
  props: {
    selectedChat: {
      type: Object,
      default: null
    },
    showCommonFiles: {
      type: Boolean,
      default: false
    },
    focusedChat: {
      type: String,
      default: ''
    }
  },
  setup(props, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const { me } = useMe(root)
    const isChat = computed(() => root.$route.name === 'chat')
    const useFamilyChat = computed(() => root.$store.getters.useFamilyChat)

    const toggleFamilyChat = () => {
      root.$store.dispatch(TOGGLE_FAMILY_CHAT)
    }

    const showFamilyChat = computed(() => root.$store.getters.show_family_chat)
    const showToggleFamilyChat = computed(
      () =>
        isChat &&
        useFamilyChat.value &&
        props.selectedChat &&
        props.selectedChat.chat_type === CHAT_TYPE.GROUP &&
        me.category_id !== USER_CATEGORY.FAMILY
    )

    return {
      isSp,
      me,
      isChat,
      showFamilyChat,
      showToggleFamilyChat,
      toggleFamilyChat
    }
  }
})
</script>

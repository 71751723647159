<template src="./PlatinumRegister.html" />
<style lang="scss" scoped src="./PlatinumRegister.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import { FETCH_BUSINESS_PROFILE, REGIST_PLATINUM } from '@/store/types'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import ProfileModal from '@/compornents/ProfileModal/ProfileModal'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'

export default defineComponent({
  name: 'PlatinumRegister',
  title() {
    return 'プロフィール'
  },
  components: {
    HeaderRight,
    ProfileModal,
    AvatarImg
  },
  setup(_, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const { me } = useMe(root)
    const agreementCheckbox = ref(false)
    const isError = ref(false)
    const isAgreed = computed(() => agreementCheckbox.value)
    const registerPlatinum = () => {
      root.$store
        .dispatch(REGIST_PLATINUM)
        .then(() => {
          return root.$store.dispatch(FETCH_BUSINESS_PROFILE)
        })
        .then(() => {
          root.$notify({
            title: '',
            message:
              'プラチナ会員登録を行いました。<br>プラチナページ編集から情報を登録して下さい。',
            dangerouslyUseHTMLString: true,
            type: 'success'
          })
        })
        .then(() => {
          root.$router.push({ name: 'platinum_page' })
        })
        .catch(error => {
          isError.value = true
          console.error('プラチナ会員申請が失敗しました。エラー:', error)
          root.$notify({
            title: '',
            message: 'プラチナ会員申請が失敗しました。',
            type: 'error'
          })
        })
    }

    return {
      isSp,
      me,
      isAgreed,
      isError,
      agreementCheckbox,
      registerPlatinum
    }
  }
})
</script>

import * as http from '@/utils/api'
import {
  CLEAR_FILE_SEARCH_WORD,
  DELETE_FILE,
  FETCH_FILES,
  INPUT_FILE_SEARCH_WORD
} from './types'
import { ENDPOINT_FILE, ENDPOINT_FILES } from '@/constants/endpoint'
import { LOADING_TYPE_FILES } from '@/constants/constant'

export default {
  state: {
    files: [],
    file_total_page: 0,
    fileSearchWord: ''
  },
  mutations: {
    [FETCH_FILES](state, responseData) {
      state.files = responseData.files
      state.file_total_page = responseData.total_page
    },
    [INPUT_FILE_SEARCH_WORD](state, payload) {
      state.fileSearchWord = payload
    },
    [CLEAR_FILE_SEARCH_WORD](state) {
      state.fileSearchWord = ''
    }
  },
  getters: {
    files: state =>
      state.fileSearchWord
        ? state.files.filter(
            f => f.file_name.indexOf(state.fileSearchWord) !== -1
          )
        : state.files,
    file_total_page: state => state.file_total_page,
    fileSearchWord: state => state.fileSearchWord
  },
  actions: {
    [FETCH_FILES]: async ({ commit, dispatch }, params) => {
      await http
        .get(ENDPOINT_FILES, params, LOADING_TYPE_FILES)
        .then(response => {
          commit(FETCH_FILES, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [DELETE_FILE]: async ({ dispatch }, params) => {
      await http.del(ENDPOINT_FILE, { data: params }).catch(error => {
        http.handleError(dispatch, error)
      })
    },
    [INPUT_FILE_SEARCH_WORD]({ commit }, payload) {
      commit(INPUT_FILE_SEARCH_WORD, payload)
    },
    [CLEAR_FILE_SEARCH_WORD]({ commit }) {
      commit(CLEAR_FILE_SEARCH_WORD)
    }
  }
}

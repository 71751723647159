<template src="./BusinessIdentificationStatus.html" />
<style lang="scss" scoped src="./BusinessIdentificationStatus.scss"></style>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import { ID_CHECKED_STATUS } from '@/constants/constant'
import { GET_BUSINESS_IDENTIFICATION } from '@/store/types'

export default defineComponent({
  name: 'BusinessIdentificationStatus',
  setup(_, { root }) {
    root.$store.dispatch(GET_BUSINESS_IDENTIFICATION)
    return {
      isSp: computed(() => root.$store.getters.isSp),
      isManager: computed(() => root.$store.getters.isManager),
      businessIdentificationStatus: computed(
        () => root.$store.getters.businessIdentificationStatus
      ),
      businessIdentificationDenialReasonText: computed(
        () => root.$store.getters.businessIdentificationDenialReasonText
      ),
      ID_CHECKED_STATUS
    }
  }
})
</script>

import * as http from '@/utils/api'
import { POST_PR, FETCH_PR } from './types'
import { ENDPOINT_PR } from '@/constants/endpoint'

export default {
  state: {
    pr: null
  },
  mutations: {
    [FETCH_PR](state, responseData) {
      if (responseData && responseData.pr) {
        state.pr = responseData.pr
      }
    }
  },
  getters: {
    pr: state => state.pr
  },
  actions: {
    [FETCH_PR]: async ({ commit }) => {
      try {
        const response = await http.get(ENDPOINT_PR)
        commit(FETCH_PR, response.data)
      } catch (error) {
        console.error('Error in FETCH_PR action:', error)
      }
    },
    [POST_PR]: async ({ dispatch }, prData) => {
      try {
        const response = await http.post(ENDPOINT_PR, prData)
        if (response.data.message === 'send email') {
          dispatch(FETCH_PR)
        }
      } catch (error) {
        console.error('Error in POST_PR action:', error)
        http.handleError(dispatch, error)
      }
    }
  }
}

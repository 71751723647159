import * as http from '@/utils/api'
import { CLEAR_RECORDS, FETCH_RECORDS, POST_RECORD } from './types'
import { LOADING_TYPE_NONE, LOADING_TYPE_RECORDS } from '@/constants/constant'
import { ENDPOINT_RECORD, ENDPOINT_RECORDS } from '@/constants/endpoint'

export default {
  state: {
    records: []
  },
  mutations: {
    [FETCH_RECORDS](state, responseData) {
      state.records = responseData.records
    },
    [CLEAR_RECORDS](state) {
      state.records = []
    }
  },
  getters: {
    records: state => state.records
  },
  actions: {
    [FETCH_RECORDS]: async ({ dispatch, commit }, params) => {
      await http
        .get(ENDPOINT_RECORDS, params, LOADING_TYPE_RECORDS)
        .then(response => {
          commit(FETCH_RECORDS, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [CLEAR_RECORDS]({ commit }) {
      commit(CLEAR_RECORDS)
    },
    [POST_RECORD]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_RECORD, params, LOADING_TYPE_NONE)
        .catch(error => {
          console.log(error)
          http.handleError(dispatch, error)
        })
    }
  }
}

<template>
  <div>
    <div class="modal-backdrop fade show"></div>
    <div
      id="send_menu"
      class="modal fade send_menu show"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style="display: block"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <ul class="send_menu">
            <div class="top-sm">
              <li @click="handleClickCamera">
                <label
                  data-toggle="modal"
                  data-target="#send_menu"
                  class="choosefile"
                >
                  <span class="icon iconv2-camera"></span> カメラ
                </label>
              </li>
              <li v-if="false">
                <label
                  data-toggle="modal"
                  data-target="#send_menu"
                  class="choosefile"
                >
                  <span class="icon iconv2-image"></span> カメラロール
                </label>
              </li>
              <li @click="handleClickFolder">
                <label
                  data-toggle="modal"
                  data-target="#send_menu"
                  class="choosefile"
                >
                  <span class="icon iconv2-file"></span> ファイルを選択
                </label>
              </li>
            </div>
            <div class="bot-sm">
              <li @click="$emit('closeCameraMenu')">
                <a href="javascript:void(0)" class="no-bd" data-dismiss="modal"
                  ><span class="icon iconv2-close"></span>キャンセル</a
                >
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>

    <div style="display: none">
      <input
        ref="inputFileCamera"
        type="file"
        name="file"
        accept="image/*"
        capture="camera"
        @change="e => $emit('inputFile', e)"
      />

      <input
        ref="inputFileFolder"
        type="file"
        name="file"
        @change="e => $emit('inputFile', e)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'Camera',
  setup() {
    const inputFileCamera = ref('')
    const handleClickCamera = () => {
      inputFileCamera.value.click()
    }

    const inputFileFolder = ref('')
    const handleClickFolder = () => {
      inputFileFolder.value.click()
    }

    return {
      handleClickCamera,
      handleClickFolder,
      inputFileCamera,
      inputFileFolder
    }
  }
})
</script>

<template src="./PlatinumP.html" />
<style lang="scss" scoped src="./PlatinumP.scss"></style>
<script>
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import axios from 'axios'
import PlatinumMenu from '@/compornents/PlatinumMenu/PlatinumMenu'
import PlatinumSelect from '@/compornents/PlatinumSelect/PlatinumSelect'
import { ID_CHECKED_STATUS } from '@/constants/constant'
import {
  GET_PLATINUM,
  GET_TMP_PLATINUM,
  POST_TMP_PLATINUM,
  GET_OPTIONS,
  GET_IMGS,
  GET_TMP_OPTIONS,
  GET_TMP_IMGS,
  UPLODE_TMP_IMG
} from '@/store/types'
import Camera from '@/compornents/Camera'
import { BRAND_URI } from '@/constants/endpoint'
import { canPreview, iconExists, getExt } from '@/utils/common'
import { createImage, isImage, isPDF } from '@/utils/image'
import { getWindowHeight } from '@/utils/window'
import { COPY_RIGHT, GANRE_LIST } from '@/constants/constant'
export default defineComponent({
  name: 'PlatinumP',
  title() {
    return 'プラチナ会員'
  },
  components: {
    HeaderRight,
    AvatarImg,
    PlatinumMenu,
    PlatinumSelect,
    Camera
  },
  setup(_, { root }) {
    const { me } = useMe(root)
    const isManage = computed(() => root.$store.getters.isManage)
    if (!isManage.value) {
      root.$router.push({ name: 'home' })
    }
    const step = ref(1)
    const isPlatinum = computed(() => root.$store.getters.isPlatinum)
    const isOptions = ref([])
    const isTmpPlatinum = computed(() => root.$store.getters.isTmpPlatinum)
    const maxLength250 = 250
    const maxLength70 = 70
    const filesToUpload = ref([])
    const fileClear = ref(0)
    const imgId = ref([])
    const tmp = ref(0)
    const tmpdata = ref(3)

    let initForm = null
    initForm = {
      office_id: '',
      company_name: '',
      introduction: '',
      feature_1: '',
      feature_2: '',
      feature_3: '',
      main_genre: 0,
      sub_genre_1: 0,
      sub_genre_2: 0,
      sub_genre_3: 0,
      sub_genre_4: 0,
      main_other: '',
      sub_other_1: '',
      sub_other_2: '',
      sub_other_3: '',
      sub_other_4: '',
      director: '',
      director_comment: '',
      has_facility_materials: '0',
      facility_material_name: '',
      facility_material_file_path: '',
      facility_material_file_size: '',
      facility_material_file_extension: ''
    }
    const form = reactive(initForm)

    const fetchTmpData = async () => {
      try {
        await root.$store.dispatch(GET_TMP_PLATINUM)
        if (isTmpPlatinum.value) {
          await root.$store.dispatch(GET_TMP_OPTIONS)
          await root.$store.dispatch(GET_TMP_IMGS)
          form.office_id = isTmpPlatinum.value.office_id || ''
          form.company_name = isTmpPlatinum.value.company_name || ''
          form.introduction = isTmpPlatinum.value.introduction || ''
          form.feature_1 = isTmpPlatinum.value.feature_1 || ''
          form.feature_2 = isTmpPlatinum.value.feature_2 || ''
          form.feature_3 = isTmpPlatinum.value.feature_3 || ''
          console.log('Before setting main_genre:', form.main_genre)
          form.main_genre = Number(isTmpPlatinum.value.main_genre) || 0
          console.log('After setting main_genre:', form.main_genre)
          form.sub_genre_1 = Number(isTmpPlatinum.value.sub_genre_1) || 0
          form.sub_genre_2 = Number(isTmpPlatinum.value.sub_genre_2) || 0
          form.sub_genre_3 = Number(isTmpPlatinum.value.sub_genre_3) || 0
          form.sub_genre_4 = Number(isTmpPlatinum.value.sub_genre_4) || 0
          form.main_other = isTmpPlatinum.value.main_other || ''
          form.sub_other_1 = isTmpPlatinum.value.sub_other_1 || ''
          form.sub_other_2 = isTmpPlatinum.value.sub_other_2 || ''
          form.sub_other_3 = isTmpPlatinum.value.sub_other_3 || ''
          form.sub_other_4 = isTmpPlatinum.value.sub_other_4 || ''
          form.director = isTmpPlatinum.value.director || ''
          form.director_comment = isTmpPlatinum.value.director_comment || ''
          form.has_facility_materials =
            isTmpPlatinum.value.has_facility_materials || '0'
          form.facility_material_name =
            isTmpPlatinum.value.facility_material_name || ''
          form.facility_material_file_path =
            isTmpPlatinum.value.facility_material_file_path || ''
          form.facility_material_file_size =
            isTmpPlatinum.value.facility_material_file_size || ''
          form.facility_material_file_extension =
            isTmpPlatinum.value.facility_material_file_extension || ''
          if (form.has_facility_materials == '1') {
            fileClear.value = 1
          }
          tmp.value = 0
          tmpdata.value = 0
          isOptions.value = root.$store.getters.isOptions
          fetchTmpImgData()
        } else {
          fetchPlatinumData()
        }
      } catch (error) {
        console.error('Failed to fetch platinum data:', error)
      }
    }
    fetchTmpData()

    const fetchPlatinumData = async () => {
      try {
        await root.$store.dispatch(GET_PLATINUM)
        await root.$store.dispatch(GET_OPTIONS)
        await root.$store.dispatch(GET_IMGS)
        if (isPlatinum.value) {
          form.office_id = isPlatinum.value.office_id || ''
          form.company_name = isPlatinum.value.company_name || ''
          form.introduction = isPlatinum.value.introduction || ''
          form.feature_1 = isPlatinum.value.feature_1 || ''
          form.feature_2 = isPlatinum.value.feature_2 || ''
          form.feature_3 = isPlatinum.value.feature_3 || ''
          console.log('Before setting main_genre:', form.main_genre)
          form.main_genre = Number(isPlatinum.value.main_genre) || 0
          console.log('After setting main_genre:', form.main_genre)
          form.sub_genre_1 = Number(isPlatinum.value.sub_genre_1) || 0
          form.sub_genre_2 = Number(isPlatinum.value.sub_genre_2) || 0
          form.sub_genre_3 = Number(isPlatinum.value.sub_genre_3) || 0
          form.sub_genre_4 = Number(isPlatinum.value.sub_genre_4) || 0
          form.main_other = isPlatinum.value.main_other || ''
          form.sub_other_1 = isPlatinum.value.sub_other_1 || ''
          form.sub_other_2 = isPlatinum.value.sub_other_2 || ''
          form.sub_other_3 = isPlatinum.value.sub_other_3 || ''
          form.sub_other_4 = isPlatinum.value.sub_other_4 || ''
          form.director = isPlatinum.value.director || ''
          form.director_comment = isPlatinum.value.director_comment || ''
          form.has_facility_materials =
            isPlatinum.value.has_facility_materials || '0'
          form.facility_material_name =
            isPlatinum.value.facility_material_name || ''
          form.facility_material_file_path =
            isPlatinum.value.facility_material_file_path || ''
          form.facility_material_file_size =
            isPlatinum.value.facility_material_file_size || ''
          form.facility_material_file_extension =
            isPlatinum.value.facility_material_file_extension || ''
          if (form.has_facility_materials == '1') {
            fileClear.value = 1
          }
        } else {
          console.warn('isPlatinum.value is null or undefined.')
        }
        isOptions.value = root.$store.getters.isTmpOptions
        fetchImgData()
        tmp.value = 1
      } catch (error) {
        console.error('Failed to fetch platinum data:', error)
      }
    }

    const isLastOptionComplete = computed(() => {
      if (isOptions.value.length === 0) {
        return true
      }
      const lastOption = isOptions.value[isOptions.value.length - 1]
      const isOptionLimitReached = isOptions.value.length >= 20
      return (
        lastOption &&
        lastOption.option &&
        lastOption.number &&
        (lastOption.unit === '0' || lastOption.unit === '1') &&
        !isOptionLimitReached
      )
    })
    const addOption = () => {
      isOptions.value.push({
        option: '',
        number: '',
        unit: '',
        postscript: ''
      })
    }
    const handleAddOption = () => {
      if (isLastOptionComplete.value) {
        addOption()
      } else {
        console.log('All fields are not complete')
      }
    }

    const deletOption = index => {
      isOptions.value.splice(index, 1)
    }

    const selectedGanruMain = computed(() =>
      form.main_genre
        ? GANRE_LIST.find(p => p.value === parseInt(form.main_genre)).label
        : ''
    )
    const selectedGanruSub1 = computed(() =>
      form.sub_genre_1
        ? GANRE_LIST.find(p => p.value === parseInt(form.sub_genre_1)).label
        : ''
    )
    const selectedGanruSub2 = computed(() =>
      form.sub_genre_2
        ? GANRE_LIST.find(p => p.value === parseInt(form.sub_genre_2)).label
        : ''
    )
    const selectedGanruSub3 = computed(() =>
      form.sub_genre_3
        ? GANRE_LIST.find(p => p.value === parseInt(form.sub_genre_3)).label
        : ''
    )
    const selectedGanruSub4 = computed(() =>
      form.sub_genre_4
        ? GANRE_LIST.find(p => p.value === parseInt(form.sub_genre_4)).label
        : ''
    )

    const getUnitLabel = unit => {
      if (unit === '0') {
        return '円(税込)/実費'
      } else if (unit === '1') {
        return '単位/加算'
      } else {
        return ''
      }
    }

    const sumple = async () => {
      try {
        const formData = new FormData()
        for (const [key, value] of Object.entries(form)) {
          formData.append(key, value)
        }
        addToFormData(formData, 'isOptions', isOptions.value)
        if (attachFile2.value) {
          formData.append('file', attachFile2.value)
        }
        await root.$store.dispatch(POST_TMP_PLATINUM, formData)
        if (filesToUpload.value.length > 0 || imgId.value.length > 0) {
          const formData = new FormData()
          filesToUpload.value.forEach(file => {
            formData.append('images[]', file)
          })
          formData.append('imgId', JSON.stringify(imgId.value))
          formData.append('tmp', tmp.value)
          try {
            await root.$store.dispatch(UPLODE_TMP_IMG, formData)
          } catch (error) {
            console.error('Failed to upload images:', error)
          }
        }
        const params = {
          office_number: me.office_number || me.other_office_number
        }

        const addParamsToUrl = (url, params) => {
          const queryString = new URLSearchParams(params).toString()
          return `${url}?${queryString}`
        }
        axios
          .get(BRAND_URI, { params })
          .then(response => {
            console.log('データ送信成功:', response.data)
            const fullUrl = addParamsToUrl(BRAND_URI, params)
            window.open(fullUrl)
          })
          .catch(error => {
            console.error('データ送信エラー:', error)
          })
      } catch (error) {
        console.error('Failed to fetch platinum data:', error)
      }
    }

    const prev = () => {
      step.value = 1
      window.scroll(0, 0)
    }

    const addToFormData = (formData, key, value) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${key}[${index}][option]`, item.option)
          formData.append(`${key}[${index}][number]`, item.number)
          formData.append(`${key}[${index}][unit]`, item.unit)
          formData.append(`${key}[${index}][postscript]`, item.postscript)
        })
      } else {
        formData.append(key, JSON.stringify(value))
      }
    }

    const isImgs = ref([])
    const imgFiles = ref([])
    const showCameraMenu = ref(false)
    const showCameraNo = ref(0)
    const attachFile1 = ref('')
    const uploadFileData1 = ref('')
    const fileRef1 = ref(null)
    const isFileSelected = ref(false)
    const showPreview = ref(false)
    const imgLimit = ref(true)
    const inputFile = e => {
      const files = e.target.files || e.dataTransfer.files
      if (!isImage(getExt(files[0].name))) {
        root.$notify({
          title: '',
          message: '画像ファイルを選択してください',
          type: 'error'
        })
        switch (showCameraNo.value) {
          case 1:
            fileRef1.value.value = null
            break
        }
        return
      }
      showCameraMenu.value = false
      switch (showCameraNo.value) {
        case 1:
          attachFile1.value = files[0]
          createImage(attachFile1.value, uploadFileData1)
          imgFiles.value.push(files[0])
          break
      }
    }
    const canPreviewImg = no => {
      switch (no) {
        case 1:
          return attachFile1.value ? canPreview(attachFile1.value.name) : false
      }
    }

    const canUpload = computed(() => attachFile1.value)

    const clearFile = no => {
      switch (no) {
        case 1:
          attachFile1.value = ''
          uploadFileData1.value = ''
          fileRef1.value.value = null
          break
      }
    }

    const fetchImgData = async () => {
      try {
        await root.$store.dispatch(GET_IMGS)
        const images = root.$store.getters.isImgs
        const processedImages = images.filter(
          img => img.platinum_img_url !== ''
        )
        isImgs.value = processedImages.map(img => ({
          url: img.platinum_img_url,
          id: img.id
        }))
        imgId.value = processedImages.map(img => ({
          id: img.id
        }))
        showPreview.value = isImgs.value.length > 0
      } catch (error) {
        console.error('Failed to fetch platinum data:', error)
      }
    }
    const fetchTmpImgData = async () => {
      try {
        await root.$store.dispatch(GET_TMP_IMGS)
        const images = root.$store.getters.isTmpImgs
        const processedImages = images.filter(
          img => img.platinum_img_url !== ''
        )
        isImgs.value = processedImages.map(img => ({
          url: img.platinum_img_url,
          id: img.id
        }))
        imgId.value = processedImages.map(img => ({
          id: img.id
        }))
        showPreview.value = isImgs.value.length > 0
        if (isImgs.value.length >= 20) {
          imgLimit.value = false
        } else {
          imgLimit.value = true
        }
      } catch (error) {
        console.error('Failed to fetch platinum data:', error)
      }
    }

    const handleUpload = () => {
      const inputFileElement = document.querySelector('input[type="file"]')
      const files = inputFileElement.files
      if (files.length > 0) {
        Array.from(files).forEach(file => {
          const reader = new FileReader()
          const existingFiles = filesToUpload.value || []

          reader.onload = e => {
            // 既存のデータと新しいデータを統合
            isImgs.value.push({
              url: e.target.result,
              name: file.name,
              size: file.size,
              type: file.type
            })

            existingFiles.push(file)
            filesToUpload.value = [...existingFiles]
            if (isImgs.value.length >= 20) {
              imgLimit.value = false
            }
          }
          reader.readAsDataURL(file)
        })

        // 入力フィールドのリセット
        document.querySelector('input[type="file"]').value = null
        attachFile1.value = null
        inputFileElement.value = null
        showPreview.value = true
        isFileSelected.value = false
      }
    }

    const removeImage = index => {
      const remocedFileName = isImgs.value[index].name
      const remocedFileId = isImgs.value[index].id
      isImgs.value.splice(index, 1)
      if (remocedFileId) {
        imgId.value = imgId.value.filter(img => img.id !== remocedFileId)
      } else {
        filesToUpload.value = filesToUpload.value.filter(
          file => file.name !== remocedFileName
        )
      }
      if (isImgs.value.length < 20) {
        imgLimit.value = true
      }
    }

    const canPreviewFile = computed(() => canPreview(attachFile.value.name))
    const attachFile = ref(null)
    const uploadFileData = ref('')
    const fileName = ref('')
    const fileExt = ref('')

    const showInvalidErrorMsg = ref(false)

    const showCameraMenu2 = ref(false)
    const showCameraNo2 = ref(0)
    const attachFile2 = ref('')
    const uploadFileData2 = ref('')
    const fileRef2 = ref(null)
    const inputFile2 = e => {
      const files = e.target.files || e.dataTransfer.files
      if (!isPDF(getExt(files[0].name))) {
        root.$notify({
          title: '',
          message: 'PDFファイルを選択してください',
          type: 'error'
        })
        switch (showCameraNo2.value) {
          case 1:
            fileRef2.value.value = null
            break
        }
        return
      }
      showCameraMenu2.value = false
      switch (showCameraNo2.value) {
        case 1: {
          attachFile2.value = files[0]
          const reader = new FileReader()
          reader.onload = event => {
            uploadFileData2.value = event.target.result
          }
          reader.readAsDataURL(attachFile2.value)
          form.has_facility_materials = '1'
          break
        }
      }
    }
    const canPreviewFile2 = no => {
      switch (no) {
        case 1:
          return attachFile2.value ? canPreview(attachFile2.value.name) : false
      }
    }

    const canUpload2 = computed(() => uploadFileData2.value)

    const clearFile2 = no => {
      switch (no) {
        case 1:
          attachFile2.value = ''
          uploadFileData2.value = ''
          fileRef2.value.value = null
          break
      }
    }
    const clearFile3 = () => {
      fileClear.value = 0
      form.has_facility_materials = '0'
      form.facility_material_name = ''
      form.facility_material_file_path = ''
      form.facility_material_file_size = ''
      form.facility_material_file_extension = ''
    }

    const sample_data = () => {
      tmpdata.value = '0'
      fetchTmpData()
    }

    const text_data = () => {
      tmpdata.value = '1'
      fetchPlatinumData()
    }

    return {
      loading: computed(() => root.$store.getters.loading),
      isSp: computed(() => root.$store.getters.isSp),
      me,
      isPlatinum: computed(() => root.$store.getters.isPlatinum),
      isOptions,
      initForm,
      isTmpPlatinum,
      getUnitLabel,
      form,
      step,
      tmp,
      tmpdata,
      text_data,
      canPreviewFile,
      attachFile,
      fileName,
      fileExt,
      uploadFileData,
      iconExists,
      sumple,
      prev,
      createImage,
      isImage,
      isLastOptionComplete,
      addOption,
      handleAddOption,
      deletOption,
      fetchTmpData,
      fetchPlatinumData,
      sample_data,
      selectedGanruMain,
      selectedGanruSub1,
      selectedGanruSub2,
      selectedGanruSub3,
      selectedGanruSub4,
      showInvalidErrorMsg,
      COPY_RIGHT,
      GANRE_LIST,
      windowHeight: getWindowHeight(74),
      ID_CHECKED_STATUS,
      inputFile,
      removeImage,
      imgLimit,
      showPreview,
      isFileSelected,
      showCameraMenu,
      showCameraNo,
      canPreviewImg,
      uploadFileData1,
      attachFile1,
      canUpload,
      clearFile,
      handleUpload,
      fileRef1,
      isImgs,
      maxLength250,
      maxLength70,
      inputFile2,
      showCameraMenu2,
      showCameraNo2,
      canPreviewFile2,
      uploadFileData2,
      attachFile2,
      canUpload2,
      clearFile2,
      fileRef2,
      clearFile3,
      fileClear
    }
  }
})
</script>

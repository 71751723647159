<template src="./PlatinumMenu.html" />
<style lang="scss" scoped src="./PlatinumMenu.scss"></style>
<script>
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'PlatinumMenu',
  components: {},
  props: {
    business_profile: {
      type: Object,
      require: true
    }
  },
  setup(_, { root }) {
    const page = computed(() => root.$route.name)
    const me = computed(() => root.$store.getters.me)
    const businessProfile = computed(() => root.$store.getters.business_profile)

    return {
      page,
      me,
      businessProfile
    }
  }
})
</script>

<template src="./ManageIdentification.html" />
<style lang="scss" scoped src="./ManageIdentification.scss"></style>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import BusinessIdentificationStatus from '@/compornents/BusinessIdentificationStatus/BusinessIdentificationStatus'

export default defineComponent({
  name: 'ManageIdentification',
  components: {
    HeaderRight,
    BusinessIdentificationStatus
  },
  title() {
    return '事業所許認可証'
  },
  setup(_, { root }) {
    const { me } = useMe(root)
    const isSp = computed(() => root.$store.getters.isSp)
    const isManage = computed(() => root.$store.getters.isManage)
    const isManager = computed(() => root.$store.getters.isManager)

    if (isSp.value || !isManage.value) {
      root.$router.push({ name: 'home' })
    }

    return {
      me,
      isSp,
      isManage,
      isManager
    }
  }
})
</script>

<template src="./ProfileView.html" />
<style lang="scss" scoped src="./ProfileView.scss"></style>
<script>
import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch
} from '@vue/composition-api'
import { GET_POST_CODE, POST_USER_PROFILE, SET_LOADING } from '@/store/types'
import {
  CHAT_TYPE,
  GENDER_LIST,
  ID_CHECKED_STATUS,
  LOADING_TYPE_PAGE,
  PREFECTURE_LIST,
  SUB_CATEGORY,
  USER_CATEGORY
} from '@/constants/constant'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import { getThisYear } from '@/utils/date'
import '@/comporsables/useVeeValidate'
import { canPreview, getExt, sha256 } from '@/utils/common'
import { createImage, isImage, toBlob } from '@/utils/image'
import { putS3Public } from '@/comporsables/useAws'
import Camera from '@/compornents/Camera'
import { getWindowHeight } from '@/utils/window'

export default defineComponent({
  name: 'ProfileView',
  components: {
    AvatarImg,
    Camera
  },
  props: {
    user: {
      type: Object,
      require: true
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    showSendMessage: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const me = computed(() => root.$store.getters.me)
    const isManager = computed(() => root.$store.getters.isManager)
    const isEdit = ref(false)
    const form = reactive({ ...props.user })
    const page = computed(() => root.$route.name)
    const identificationStatus = computed(
      () => root.$store.getters.identificationStatus
    )

    const changeEditMode = () => {
      if (isSp.value) {
        root.$scrollTo('.avatar', 0, {
          container: '.viewModalContact',
          force: true,
          offset: -100,
          x: false,
          y: true
        })
      }

      isEdit.value = true
      const birth = props.user.birthday.match(
        /([0-9]{4})年([0-9]{2})月([0-9]{2})日/
      )
      form.year = birth[1]
      form.month = birth[2]
      form.day = birth[3]
    }

    const postProfile = () => {
      if (me.value.category_id === USER_CATEGORY.FAMILY) {
        delete form.office_number
      } else {
        delete form.home_phone
      }

      const param = {
        ...form,
        birthday: `${form.year}-${form.month}-${form.day}`
      }

      if (attachFile.value) {
        root.$store.dispatch(SET_LOADING, {
          type: LOADING_TYPE_PAGE,
          status: true
        })

        const fileExt = getExt(attachFile.value.name)
        const attachFileKey = ref('')
        sha256(`user_${me.value.user_id}`).then(hash => {
          attachFileKey.value = `profile/${me.value.user_id}@${hash}.${fileExt}`
          putS3Public(
            attachFileKey.value,
            isImage(fileExt.value)
              ? toBlob(uploadFileData.value, attachFile.value.type)
              : attachFile.value,
            attachFile.value.type
          )
            .then(() => {
              const paramWith = {
                ...param,
                profile_img_url: attachFileKey.value
              }

              root.$store
                .dispatch(POST_USER_PROFILE, paramWith)
                .then(() => location.reload())
                .catch(() => undefined)
            })
            .catch(err => {
              console.log(err)
              root.$notify({
                title: '',
                message: 'ファイルのアップロードに失敗しました',
                type: 'error'
              })
            })
        })
      } else {
        root.$store
          .dispatch(POST_USER_PROFILE, param)
          .then(() => location.reload())
          .catch(() => undefined)
      }
    }

    const thisYear = getThisYear()
    let years = []
    for (let i = 1950; i <= thisYear; i++) {
      years = [...years, i]
    }
    let monthList = []
    for (let i = 1; i <= 12; i++) {
      monthList = [...monthList, ('00' + i).slice(-2)]
    }
    let dayList = []
    for (let i = 1; i <= 31; i++) {
      dayList = [...dayList, ('00' + i).slice(-2)]
    }

    const postCodeIsInvalid = ref(false)
    watch(
      () => form.postcode,
      v =>
        root.$store
          .dispatch(GET_POST_CODE, v)
          .then(() => {
            postCodeIsInvalid.value = false
            form.prefecture_id = root.$store.getters.address.prefecture_id
            form.city = root.$store.getters.address.city
          })
          .catch(() => {
            postCodeIsInvalid.value = true
          })
    )

    const subCategoryList = computed(() => {
      if (props.user.category_id === USER_CATEGORY.BUSINESS) {
        return SUB_CATEGORY.BUSINESS
      }
      if (props.user.category_id === USER_CATEGORY.MEDICAL) {
        return SUB_CATEGORY.MEDICAL
      }
      return []
    })

    const viewList = [
      { label: '表示', value: '1' },
      { label: '非表示', value: '0' }
    ]

    const viewNameDisplayGender = computed(
      () =>
        viewList.find(v => Number(v.value) === props.user.is_display_gender_id)
          .label
    )
    const viewNameDisplayBirthday = computed(
      () =>
        viewList.find(v => Number(v.value) === props.user.is_display_birthday)
          .label
    )

    const attachFile = ref(null)
    const uploadFileData = ref('')
    const fileName = ref('')
    const fileExt = ref('')
    const inputFile = e => {
      const files = e.target.files || e.dataTransfer.files
      attachFile.value = files[0]
      fileExt.value = getExt(attachFile.value.name)
      fileName.value = attachFile.value.name.split(`.${fileExt.value}`)[0]
      if (isImage(fileExt.value)) {
        createImage(attachFile.value, uploadFileData, true)
      }
    }

    const canPreviewFile = computed(() =>
      attachFile.value ? canPreview(attachFile.value.name) : false
    )
    const resetFile = () => {
      attachFile.value = null
      uploadFileData.value = ''
      fileExt.value = ''
      fileName.value = ''
    }

    const showCameraMenu = ref(false)

    return {
      me,
      identificationStatus,
      page,
      isSp,
      isManager,
      form,
      isEdit,
      years,
      monthList,
      dayList,
      USER_CATEGORY,
      GENDER_LIST,
      changeEditMode,
      postProfile,
      PREFECTURE_LIST,
      CHAT_TYPE,
      postCodeIsInvalid,
      subCategoryList,
      viewList,
      viewNameDisplayGender,
      viewNameDisplayBirthday,
      ID_CHECKED_STATUS,
      inputFile,
      resetFile,
      fileName,
      uploadFileData,
      canPreviewFile,
      showCameraMenu,
      windowHeight: getWindowHeight(74)
    }
  }
})
</script>

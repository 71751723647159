import * as http from '@/utils/api'
import {
  CLEAR_USER_SEARCH_RESULT,
  GET_USER_SEARCH,
  GET_OFFICE_SEARCH,
  GET_OTHER_OFFICE_SEARCH,
  PATCH_USER_REGISTER_CONFIRM,
  PATCH_OWNER_REGISTER_CONFIRM,
  POST_USER_PROFILE,
  POST_USER_REGISTER,
  POST_OWNER,
  POST_FAMILY
} from './types'
import {
  ENDPOINT_USER_PROFILE,
  ENDPOINT_USER_REGISTER,
  ENDPOINT_USER_REGISTER_CONFIRM,
  ENDPOINT_OWNER_REGISTER_CONFIRM,
  ENDPOINT_USER_SEARCH,
  ENDPOINT_OFFICE_SEARCH,
  ENDPOINT_OTHER_OFFICE_SEARCH,
  ENDPOINT_OWNER_REGISTER,
  ENDPOINT_FAMILY_REGISTER
} from '@/constants/endpoint'
import { isEmptyObject } from '@/utils/common'
import {
  LOADING_TYPE_PAGE,
  LOADING_TYPE_USER_SEARCH
} from '@/constants/constant'

export default {
  state: {
    user: null,
    office: null,
    otherOffice: null
  },
  mutations: {
    [GET_USER_SEARCH](state, responseData) {
      if (!isEmptyObject(responseData)) {
        state.user = responseData
      }
    },
    [GET_OFFICE_SEARCH](state, responseData) {
      state.office = responseData
    },
    [GET_OTHER_OFFICE_SEARCH](state, responseData) {
      state.otherOffice = responseData
    },
    [CLEAR_USER_SEARCH_RESULT](state) {
      state.user = null
    }
  },
  getters: {
    user: state => state.user,
    office: state => state.office,
    otherOffice: state => state.otherOffice
  },
  actions: {
    [POST_USER_REGISTER]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_USER_REGISTER, params, LOADING_TYPE_PAGE)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [POST_OWNER]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_OWNER_REGISTER, params, LOADING_TYPE_PAGE)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [POST_FAMILY]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_FAMILY_REGISTER, params, LOADING_TYPE_PAGE)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [POST_USER_PROFILE]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_USER_PROFILE, params, LOADING_TYPE_PAGE)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [PATCH_USER_REGISTER_CONFIRM]: async ({ dispatch }, params) => {
      await http.patch(ENDPOINT_USER_REGISTER_CONFIRM, params).catch(error => {
        http.handleError(dispatch, error)
        throw new Error()
      })
    },
    [PATCH_OWNER_REGISTER_CONFIRM]: async ({ dispatch }, params) => {
      await http.patch(ENDPOINT_OWNER_REGISTER_CONFIRM, params).catch(error => {
        http.handleError(dispatch, error)
        throw new Error()
      })
    },
    [GET_USER_SEARCH]: async ({ commit, dispatch }, min_care_id) => {
      commit(CLEAR_USER_SEARCH_RESULT)
      await http
        .get(
          ENDPOINT_USER_SEARCH + '/' + min_care_id,
          {},
          LOADING_TYPE_USER_SEARCH
        )
        .then(response => {
          commit(GET_USER_SEARCH, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [GET_OFFICE_SEARCH]: async ({ commit, dispatch }, office_number) => {
      commit(CLEAR_USER_SEARCH_RESULT)
      await http
        .get(
          ENDPOINT_OFFICE_SEARCH + '/' + office_number,
          {},
          LOADING_TYPE_USER_SEARCH
        )
        .then(response => {
          commit(GET_OFFICE_SEARCH, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [GET_OTHER_OFFICE_SEARCH]: async (
      { commit, dispatch },
      other_office_number
    ) => {
      commit(CLEAR_USER_SEARCH_RESULT)
      await http
        .get(
          ENDPOINT_OTHER_OFFICE_SEARCH + '/' + other_office_number,
          {},
          LOADING_TYPE_USER_SEARCH
        )
        .then(response => {
          commit(GET_OTHER_OFFICE_SEARCH, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [CLEAR_USER_SEARCH_RESULT]({ commit }) {
      commit(CLEAR_USER_SEARCH_RESULT)
    }
  }
}

import * as http from '@/utils/api'
import {
  GET_BUSINESS_IDENTIFICATION,
  POST_BUSINESS_IDENTIFICATION
} from './types'
import { ENDPOINT_BUSINESS_IDENTIFICATION } from '@/constants/endpoint'
import { isEmptyObject } from '@/utils/common'
import { ID_CHECKED_STATUS } from '@/constants/constant'

export default {
  state: {
    businessIdentification: null
  },
  mutations: {
    [GET_BUSINESS_IDENTIFICATION](state, responseData) {
      if (!isEmptyObject(responseData)) {
        state.businessIdentification = responseData
      }
    }
  },
  getters: {
    businessIdentificationStatus: state =>
      state.businessIdentification
        ? state.businessIdentification.status
        : ID_CHECKED_STATUS.UNAUTHENTICATED,
    businessIdentificationDenialReasonText: state =>
      state.businessIdentification
        ? state.businessIdentification.denial_reason_text
        : ''
  },
  actions: {
    [GET_BUSINESS_IDENTIFICATION]: async ({ commit, dispatch }, params) => {
      await http
        .get(ENDPOINT_BUSINESS_IDENTIFICATION, params)
        .then(response => {
          commit(GET_BUSINESS_IDENTIFICATION, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [POST_BUSINESS_IDENTIFICATION]: async ({ dispatch }, params) => {
      await http.post(ENDPOINT_BUSINESS_IDENTIFICATION, params).catch(error => {
        http.handleError(dispatch, error)
        throw new Error()
      })
    }
  }
}

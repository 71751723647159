export const PRINT_STYLE_RECORD = `
<style lang="scss">
  body {
    height: calc(100vh - 200px);
    padding: 35px 35px 25px 35px;
  }

  .title {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: #34362F;
    margin-bottom: 27px;
    letter-spacing: 1px;
  }

  .item {
    background: #FAFAFA;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
  }

  .item .title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 6px;
  }

  .item .title .cont-r {
    font-size: 11px;
    font-weight: bold;
  }

  .item .date {
    color: #ccc;
    font-size: 10.5px;
    letter-spacing: 1.5px;
  }

  .item .date span {
    color: #ccc;
    padding-left: 10px;
    margin-right: 40px;
  }

  .item .desc {
    font-size: 13px;
  }
  
  .contents {
    white-space: pre-wrap;
  }
</style>
`

export const PRINT_STYLE_REPORT = `
<style lang="scss">
  #print_make_supportRecord2 .header {
    border-bottom: 1px solid #DFE6EB;
    padding: 20px 35px 5px 35px;
    background: #FAFAFA;
    position: relative;
  }
  
  #print_make_supportRecord2 .header h3 {
    font-size: 17px;
    margin: 0;
    padding: 0;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 8px;
    color: #34362F;
  }
  
  #print_make_supportRecord2 .header p {
    font-size: 13.5px;
    color: #888888;
  }
  
  #print_make_supportRecord2 .modal-content {
    border-radius: 0;
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border: none;
  }
  
  #print_make_supportRecord2.body {
    padding: 35px 35px 25px 35px; 
  }
  
  #print_make_supportRecord2.body .title {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: #34362F;
    margin-bottom: 27px;
    letter-spacing: 1px;
  }
  
  #print_make_supportRecord2.body .desc {
    margin-bottom: 10px;
  }
  
  #print_make_supportRecord2.body .desc ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  #print_make_supportRecord2.body .desc ul li {
    border-bottom: 1px solid #707070;
    width: calc(50% - 78px);
    color: #34362F;
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 13.5px;
    padding-bottom: 5px;
  }
  #print_make_supportRecord2.body .require {
    display: none;
  }
  
  #print_make_supportRecord2.body .desc ul li span:nth-child(2) {
    color: #888888;
  }
  
  #print_make_supportRecord2 table {
    border-collapse: collapse;
    border: 1px solid #34362F;
    width: 100%;
  }
  
  #print_make_supportRecord2 table tr {
    font-size: 12px;
  }
  
  #print_make_supportRecord2 table tr span {
    min-height: 25.5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 10px;
  }
  
  #print_make_supportRecord2 table th {
    border: 1px solid #34362F;
  }
  
  #print_make_supportRecord2 table th span {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  #print_make_supportRecord2 table th:nth-child(1) {
    width: 110px;
  }
  
  #print_make_supportRecord2 table th:nth-child(2) {
    width: 110px;
  }
  
  #print_make_supportRecord2 table td {
    border: 1px solid #34362F;
  }
  
  .body-record {
    height: calc(100vh - 200px);
    padding: 40px 20px 0;
  }
  
  .body-record .item {
    background: #FAFAFA;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
  }
  
  .body-record .item .title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 6px;
  }
  
  .body-record .item .title .cont-r {
    font-size: 11px;
    font-weight: bold;
  }
  
  .body-record .item .date {
    color: #34362F;
    font-size: 10.5px;
    letter-spacing: 1.5px;
  }
  
  .body-record .item .date span {
    color: #222222;
    padding-left: 10px;
    margin-right: 40px;
  }
  
  .body-record .item .desc {
    font-size: 13px;
  }
</style>
`

export const ENV_LOCAL = 'local'
export const ENV_DEV = 'dev'
export const ENV_PRD = 'prod'
export const APP_DOMAIN = 'front.minna-de-care.com'
export const APP_NAME = 'みんなでケア'
export const COPY_RIGHT = '© Wonderful-life, Co.,Ltd.'

export const LOADING_TYPE_NONE = 'none'
export const LOADING_TYPE_PAGE = 'page'
export const LOADING_TYPE_LOGIN = 'login'
export const LOADING_TYPE_LOGOUT = 'logout'
export const LOADING_TYPE_CHAT = 'chat'
export const LOADING_TYPE_REGISTER_CHAT = 'register_chat'
export const LOADING_TYPE_PUT_CHAT_OWNER = 'put_chat_owner'
export const LOADING_TYPE_PATCH_CHAT = 'patch_chat'
export const LOADING_TYPE_DELETE_CHAT = 'delete_chat'
export const LOADING_TYPE_CONTACT = 'contact'
export const LOADING_TYPE_ADD_CONTACT = 'add_contact'
export const LOADING_TYPE_INDIVIDUAL_OR_GROUP_MESSAGE =
  'individual_or_group_message'
export const LOADING_TYPE_FAMILY_MESSAGE = 'family_message'
export const LOADING_TYPE_RECORDS = 'records'
export const LOADING_TYPE_ATTACH = 'attach'
export const LOADING_TYPE_S3_URL = 's3_url'
export const LOADING_TYPE_SCHEDULES = 'schedules'
export const LOADING_TYPE_NEXT_SCHEDULES = 'next_schedules'
export const LOADING_TYPE_SCHEDULE = 'schedule'
export const LOADING_TYPE_USER_SEARCH = 'user_search'
export const LOADING_TYPE_FILES = 'files'
export const LOADING_TYPE_RESET_PASSWORD = 'reset_password'
export const LOADING_TYPE_BUSINESS_PROFILE = 'business_profile'
export const LOADING_TYPE_POST_STAFF = 'post_staff'
export const LOADING_TYPE_INVITED_STAFFS = 'invited_staffs'
export const LOADING_TYPE_STAFFS = 'staffs'
export const LOADING_TYPE_STAFF_CHATS = 'staff_chats'
export const LOADING_TYPE_STAFF_HISTORIES = 'staff_histories'
export const LOADING_TYPE_STAFF_MONTHLY_USAGE = 'staff_monthly_usage'
export const LOADING_TYPE_STAFF_MONTHLY_USAGE_DETAIL =
  'staff_monthly_usage_detail'
export const LOADING_TYPE_DELETE_OFFICE = 'delete_office'
export const LOADING_TYPE_STAFFS_STATUS = 'delete_office'
export const LOADING_TYPE_KAIGO_KOUHYOU = 'kaigo_kouhyou'

export const URL_REG_EXP = /(?:[A-Za-z]{3,9})(?::\/\/|@)(?:(?:[A-Za-z0-9\-.]+[.:])|(?:www\.|[-;:&=+$,\w]+@))(?:[A-Za-z0-9.-]+)(?:[/\-+=&;%@.\w_~()]*)(?:[.!/\\\w-?%#~&=+()]*)/g

export const USER_CATEGORY = {
  MANAGER: 'manager',
  BUSINESS: 'business',
  MEDICAL: 'medical',
  OTHER: 'other',
  FAMILY: 'family'
}

export const USER_CATEGORY_NAMES = {
  [USER_CATEGORY.MANAGER]: 'ケアマネジャー',
  [USER_CATEGORY.BUSINESS]: '介護事業所',
  [USER_CATEGORY.MEDICAL]: '医療関係者',
  [USER_CATEGORY.OTHER]: 'その他の事業者',
  [USER_CATEGORY.FAMILY]: 'ご本人・ご家族'
}

export const GENDER_LIST = [
  { label: '男性', value: 'male' },
  { label: '女性', value: 'female' },
  { label: 'その他', value: 'other' }
]

export const USER_STATUS = {
  TEMPORARY: 'temporary',
  REGISTRATION: 'registration',
  DELETED: 'deleted'
}

export const CHAT_TYPE = {
  ALL: 'all',
  GROUP: 'group',
  INDIVIDUAL: 'individual'
}

export const CHAT_TYPE_NAME = {
  ALL: '全てのチャット',
  GROUP: 'グループチャット',
  INDIVIDUAL: '個人チャット'
}

export const MESSAGE_TYPE = {
  CARE_MEMBER: 'care_member',
  FAMILY: 'family',
  INDIVIDUAL: 'individual'
}

export const FOCUSED_CHAT = {
  FAMILY: 'family',
  INDIVIDUAL_OR_GROUP: 'individual_or_group'
}

export const RECORD_TYPE = {
  EMERGENCY: 'emergency',
  HOSPITALIZATION: 'hospitalization'
}

export const TAG_ID = {
  USER_INFO: { value: 1, label: '介護保険情報' },
  SUBMISSION: { value: 2, label: '介護事業所提出用ファイル' },
  ASSESSMENT: { value: 3, label: '実績報告書' },
  CARE_PLAN: { value: 4, label: 'ケアプラン' },
  MONTHLY_SCHEDULE: { value: 5, label: 'アセスメント' },
  MONITOR: { value: 6, label: 'モニタリング' },
  USAGE_SLIP: { value: 7, label: '提供票' },
  PERSONAL_INFO: { value: 8, label: '個人情報同意書' },
  MEDICINE_NOTE: { value: 9, label: 'お薬手帳' },
  OTHER: { value: 100, label: 'その他' }
}

export const PREFECTURE_LIST = [
  { value: 1, label: '北海道' },
  { value: 2, label: '青森県' },
  { value: 3, label: '岩手県' },
  { value: 4, label: '宮城県' },
  { value: 5, label: '秋田県' },
  { value: 6, label: '山形県' },
  { value: 7, label: '福島県' },
  { value: 8, label: '茨城県' },
  { value: 9, label: '栃木県' },
  { value: 10, label: '群馬県' },
  { value: 11, label: '埼玉県' },
  { value: 12, label: '千葉県' },
  { value: 13, label: '東京都' },
  { value: 14, label: '神奈川県' },
  { value: 15, label: '新潟県' },
  { value: 16, label: '富山県' },
  { value: 17, label: '石川県' },
  { value: 18, label: '福井県' },
  { value: 19, label: '山梨県' },
  { value: 20, label: '長野県' },
  { value: 21, label: '岐阜県' },
  { value: 22, label: '静岡県' },
  { value: 23, label: '愛知県' },
  { value: 24, label: '三重県' },
  { value: 25, label: '滋賀県' },
  { value: 26, label: '京都府' },
  { value: 27, label: '大阪府' },
  { value: 28, label: '兵庫県' },
  { value: 29, label: '奈良県' },
  { value: 30, label: '和歌山県' },
  { value: 31, label: '鳥取県' },
  { value: 32, label: '島根県' },
  { value: 33, label: '岡山県' },
  { value: 34, label: '広島県' },
  { value: 35, label: '山口県' },
  { value: 36, label: '徳島県' },
  { value: 37, label: '香川県' },
  { value: 38, label: '愛媛県' },
  { value: 39, label: '高知県' },
  { value: 40, label: '福岡県' },
  { value: 41, label: '佐賀県' },
  { value: 42, label: '長崎県' },
  { value: 43, label: '熊本県' },
  { value: 44, label: '大分県' },
  { value: 45, label: '宮崎県' },
  { value: 46, label: '鹿児島県' },
  { value: 47, label: '沖縄県' }
]

export const SUB_CATEGORY = {
  BUSINESS: [
    { value: '101', label: '訪問介護（ホームヘルプサービス）' },
    { value: '102', label: '訪問入浴介護' },
    { value: '103', label: '訪問看護' },
    { value: '104', label: '訪問リハビリテーション' },
    { value: '105', label: '居宅療養管理指導' },
    { value: '106', label: '通所介護（デイサービス）' },
    { value: '107', label: '通所リハビリテーション（デイケア）' },
    { value: '108', label: '短期入所生活介護（ショートステイ）' },
    { value: '109', label: '短期入所療養介護（ショートステイ）' },
    { value: '110', label: '特定施設入所者生活介護' },
    { value: '111', label: '福祉用具貸与' }
  ],
  MEDICAL: [
    { value: '201', label: '病院関連' },
    { value: '202', label: '在宅病院関連' },
    { value: '203', label: '歯科病院関連' },
    { value: '204', label: '薬局関連' },
    { value: '205', label: 'はり・灸関連' },
    { value: '206', label: 'PT・OT・STリハビリ関連' }
  ]
}

export const EXT_FILES = [
  'ai',
  'avi',
  'css',
  'csv',
  'dbf',
  'doc',
  'dwg',
  'exe',
  'file',
  'fla',
  'html',
  'iso',
  'jpg',
  'js',
  'json',
  'mp3',
  'mp4',
  'pdf',
  'png',
  'ppt',
  'psd',
  'rtf',
  'search',
  'svg',
  'txt',
  'xls',
  'xml',
  'zip'
]

export const SCHEDULE_COLORS = {
  1: 'bg02',
  2: 'bg06',
  3: 'bg01',
  4: 'bg07',
  5: 'bg03',
  6: 'bg05',
  7: 'bg04',
  8: 'bg08'
}

export const SCHEDULE_COLOR_CODES = {
  1: '#BDEDDB',
  2: '#5EC977',
  3: '#D8D7F3',
  4: '#F9B5FF',
  5: '#FFD6C1',
  6: '#FF9D9D',
  7: '#FFEC9D',
  8: '#EDC04B'
}

export const PAYMENTS = [
  { value: '1', label: '銀行振込' }
  // { value: '2', label: '口座振替' },
  // { value: '3', label: 'コンビニ払い' }
]

export const ID_CHECKED_STATUS = {
  UNAUTHENTICATED: 'unauthenticated',
  APPROVED: 'approved',
  DENIED: 'denied',
  INCOMPLETE: 'incomplete',
  ON_HOLD: 'on_hold',
  APPLYING: 'applying'
}

export const CHAT_STATUS = {
  ENABLE: 'enable',
  DISABLE: 'disable',
  SLEEP: 'sleep'
}

export const STAFF_HISTORY_STATUS = {
  INVITING: 'inviting',
  PARTICIPATED: 'participated',
  LEAVE: 'leave',
  STOPPED: 'stopped',
  REOPENED: 'reopened'
}

export const MAIL_USAGE_STATUS = {
  ENABLE: 'enable',
  DISABLE: 'disable'
}

export const GANRE_LIST = [
  { value: 0, label: '選択してください' },
  { value: 1, label: '訪問介護(ホームヘルプサービス)' },
  { value: 2, label: '訪問入浴介護' },
  { value: 3, label: '訪問介護' },
  { value: 4, label: '訪問リハビリテーション' },
  { value: 5, label: '居宅療養管理指導' },
  { value: 6, label: '通所介護(デイサービス)' },
  { value: 7, label: '通所リハビリテーション(デイケア)' },
  { value: 8, label: '短期入所生活介護(ショートステイ)' },
  { value: 9, label: '短期入所療養介護(ショートステイ)' },
  { value: 10, label: '特定施設入所者生活介護' },
  { value: 11, label: '福祉用具貸与' },
  { value: 12, label: 'その他' }
]

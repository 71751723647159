<template src="./FileView.html" />
<style lang="scss" scoped src="./FileView.scss"></style>
<script>
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import RecordModal from '@/compornents/RecordModal/RecordModal'
import FileListModal from '@/compornents/FileListModal/FileListModal'
import FileIcon from '@/compornents/FileIcon/FileIcon'
import {
  CHAT_TYPE,
  RECORD_TYPE,
  TAG_ID,
  USER_CATEGORY,
  MESSAGE_TYPE
} from '@/constants/constant'

export default defineComponent({
  name: 'FileView',
  components: {
    AvatarImg,
    RecordModal,
    FileListModal,
    FileIcon
  },
  props: {
    chat: {
      type: Object,
      require: true
    },
    messageType: {
      type: String,
      require: true
    },
    focusedChat: {
      type: String,
      require: true
    },
    selectedChat: {
      type: Object,
      require: true
    }
  },
  setup(props, { emit, root }) {
    const isFamily = computed(
      () => root.$store.getters.me.category_id === USER_CATEGORY.FAMILY
    )
    const showFamilyChat = computed(() => root.$store.getters.show_family_chat)
    const clickProfile = () => {
      props.chat.is_group ? emit('openEditChatModal') : emit('openProfileModal')
    }

    const recordModalStore = reactive({
      showEmergencyRecordModal: ref(false),
      closeEmergencyRecordModal: () => {
        recordModalStore.showEmergencyRecordModal = false
      },
      showHospitalRecordModal: ref(false),
      closeHospitalRecordModal: () => {
        recordModalStore.showHospitalRecordModal = false
      }
    })

    const showFileListModal = ref(false)
    const selectedTagId = ref(0)
    const openFileList = tagId => {
      selectedTagId.value = tagId
      showFileListModal.value = true
    }

    const tagList = computed(() => Object.values(TAG_ID))

    return {
      clickProfile,
      recordModalStore,
      RECORD_TYPE,
      showFileListModal,
      selectedTagId,
      openFileList,
      TAG_ID,
      tagList,
      isFamily,
      showFamilyChat,
      MESSAGE_TYPE,
      CHAT_TYPE
    }
  }
})
</script>

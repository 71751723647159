<template>
  <div>
    <div class="bnt__submit" @click="postSample('A')">Push通知テストA</div>
    <div class="bnt__submit typeb" @click="postSample('B')">
      Push通知テストB
    </div>
  </div>
</template>
<style lang="scss" scoped>
.bnt__submit {
  display: block;
  width: 240px;
  font-size: 16px;
  margin: 50px auto 0;
  background-color: #00bcd4;
  color: #fff;
  line-height: 36px;
  border: none;
  padding: 14px;
  font-weight: bold;
  transition: all 0.3s;
  letter-spacing: 2px;
  border-radius: 6px;
  outline: none;
  text-align: center;
  text-decoration: none;
  &.typeb {
    background-color: #ff7a00;
  }
}
</style>
<script>
import { defineComponent } from '@vue/composition-api'
import { POST_SAMPLE } from '@/store/types'

export default defineComponent({
  name: 'Sample',
  title() {
    return 'サンプル'
  },
  setup(_, { root }) {
    const postSample = type => {
      root.$store.dispatch(POST_SAMPLE, { type })
    }

    return {
      postSample
    }
  }
})
</script>

import * as http from '@/utils/api'
import {
  ADD_MESSAGE,
  SET_LAST_READ_MESSAGE_ID,
  CLEAR_MESSAGES,
  EDIT_MESSAGE,
  FETCH_MESSAGES,
  REMOVE_MESSAGE
} from './types'
import { ENDPOINT_MESSAGES } from '@/constants/endpoint'
import { MESSAGE_TYPE } from '@/constants/constant'

export default {
  state: {
    messages: [],
    family_messages: [],
    last_read_message_id: 0,
    last_read_family_message_id: 0,
    is_read_all_message: false,
    is_read_all_family_message: false,
    new_message_id: 0,
    new_family_message_id: 0
  },
  mutations: {
    [ADD_MESSAGE](state, payload) {
      if (payload.isFamily) {
        state.family_messages = [...state.family_messages, payload.message]
      } else {
        state.messages = [...state.messages, payload.message]
      }
    },
    [FETCH_MESSAGES](state, payload) {
      const responseData = payload.responseData
      if (payload.isFamily) {
        state.is_read_all_family_message = responseData.messages.length < 10
        if (payload.page === 1) {
          state.family_messages = responseData.messages
        } else {
          state.family_messages = [
            ...responseData.messages,
            ...state.family_messages
          ]
        }
        state.last_read_family_message_id = responseData.last_read_message_id
        state.new_family_message_id =
          responseData.messages.length > 0
            ? responseData.messages[responseData.messages.length - 1].message_id
            : 0
      } else {
        state.is_read_all_message = responseData.messages.length < 10
        if (payload.page === 1) {
          state.messages = responseData.messages
        } else {
          state.messages = [...responseData.messages, ...state.messages]
        }
        state.last_read_message_id = responseData.last_read_message_id
        state.new_message_id =
          responseData.messages.length > 0
            ? responseData.messages[responseData.messages.length - 1].message_id
            : 0
      }
    },
    [CLEAR_MESSAGES](state, isFamily) {
      if (isFamily) {
        state.family_messages = []
      } else {
        state.messages = []
      }
    },
    [REMOVE_MESSAGE](state, payload) {
      if (payload.isFamily) {
        state.family_messages = state.family_messages.filter(
          m => m.message_id !== payload.message_id
        )
      } else {
        state.messages = state.messages.filter(
          m => m.message_id !== payload.message_id
        )
      }
    },
    [EDIT_MESSAGE](state, payload) {
      if (payload.isFamily) {
        state.family_messages = state.family_messages.map(m =>
          m.message_id === payload.message.message_id ? payload.message : m
        )
      } else {
        state.messages = state.messages.map(m =>
          m.message_id === payload.message.message_id ? payload.message : m
        )
      }
    },
    [SET_LAST_READ_MESSAGE_ID](state, payload) {
      if (payload.isFamily) {
        state.last_read_family_message_id = payload.message_id
      } else {
        state.last_read_message_id = payload.message_id
      }
    }
  },
  getters: {
    messages: state => state.messages,
    family_messages: state => state.family_messages,
    last_read_message_id: state => state.last_read_message_id,
    last_read_family_message_id: state => state.last_read_family_message_id,
    is_read_all_message: state => state.is_read_all_message,
    is_read_all_family_message: state => state.is_read_all_family_message,
    new_message_id: state => state.new_message_id,
    new_family_message_id: state => state.new_family_message_id
  },
  actions: {
    [FETCH_MESSAGES]: async ({ commit, dispatch }, payload) => {
      await http
        .get(ENDPOINT_MESSAGES, payload.param, payload.loading_type)
        .then(response => {
          commit(FETCH_MESSAGES, {
            page: payload.param.page,
            responseData: response.data,
            isFamily: payload.param.message_type === MESSAGE_TYPE.FAMILY
          })
        })
        .catch(error => {
          console.log(error)
          http.handleError(dispatch, error)
        })
    },
    [ADD_MESSAGE]: ({ commit, rootState }, payload) => {
      console.log(ADD_MESSAGE, rootState.chat.selectedChat, payload.isFamily)
      if (payload.message.chat_id !== rootState.chat.selectedChat.chat_id) {
        console.log(
          '選択中ではないチャットへの送信',
          payload.message.chat_id,
          rootState.chat.selectedChat.chat_id
        )
        return
      }
      commit(ADD_MESSAGE, payload)
    },
    [CLEAR_MESSAGES]: ({ commit }, isFamily) => {
      commit(CLEAR_MESSAGES, isFamily)
    },
    [REMOVE_MESSAGE]: ({ commit }, payload) => {
      commit(REMOVE_MESSAGE, payload)
    },
    [EDIT_MESSAGE]: ({ commit }, payload) => {
      commit(EDIT_MESSAGE, payload)
    },
    [SET_LAST_READ_MESSAGE_ID]: ({ commit }, payload) => {
      commit(SET_LAST_READ_MESSAGE_ID, payload)
    }
  }
}

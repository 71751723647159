import { getEnvName } from '@/utils/common'
import { mapGetters } from 'vuex'
import { APP_NAME } from '@/constants/constant'

export default {
  created() {
    this.setPageTitle()
  },
  updated() {
    this.setPageTitle()
  },
  computed: {
    ...mapGetters([])
  },
  methods: {
    setPageTitle() {
      let { title } = this.$options
      if (title === undefined) {
        return
      }
      const envName = getEnvName() ? ` ( ${getEnvName()} )` : ''

      let pageTitle = ''
      if (title) {
        title = typeof title === 'function' ? title.call(this) : title
        pageTitle = `${title} | ${APP_NAME}${envName}`
      } else {
        pageTitle = `${APP_NAME}${envName}`
      }

      document.title = pageTitle
    }
  }
}

<template src="./Identification.html" />
<style lang="scss" scoped src="./Identification.scss"></style>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import IdentificationStatus from '@/compornents/IdentificationStatus/IdentificationStatus'

export default defineComponent({
  name: 'Identification',
  components: {
    HeaderRight,
    AvatarImg,
    IdentificationStatus
  },
  title() {
    return '本人確認認証'
  },
  setup(_, { root }) {
    const { me } = useMe(root)
    const isSp = computed(() => root.$store.getters.isSp)

    return {
      me,
      isSp
    }
  }
})
</script>

<template src="./Contacts.html" />
<style lang="scss" scoped src="./Contacts.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import ProfileView from '@/compornents/ProfileView/ProfileView'
import ProfileModal from '@/compornents/ProfileModal/ProfileModal'
import AddContactModal from '@/compornents/AddContactModal/AddContactModal'
import Contact from '@/compornents/Contact/Contact'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import {
  CLEAR_CONTACT_SEARCH_WORD,
  DELETE_CONTACT,
  FETCH_CONTACTS,
  INPUT_CONTACT_SEARCH_WORD
} from '@/store/types'
import { CHAT_TYPE, USER_CATEGORY } from '@/constants/constant'
import { getWindowHeight } from '@/utils/window'

export default defineComponent({
  name: 'Contacts',
  components: {
    HeaderRight,
    ProfileView,
    ProfileModal,
    AddContactModal,
    Contact,
    AvatarImg
  },
  title() {
    return 'コンタクト'
  },
  setup(_, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const { me } = useMe(root)
    const showAddContactModal = ref(false)
    const showContactMenu = ref(false)
    const showDeleteContactConfirm = ref(false)
    const selectedUser = ref(null)

    const toggleShowContactMenu = () => {
      showContactMenu.value = !showContactMenu.value
    }

    const contacts = computed(() => root.$store.getters.contacts)
    const loading = computed(() => root.$store.getters.loading)

    const fetchContacts = () => {
      root.$store.dispatch(FETCH_CONTACTS).then(() => {
        if (contacts.value.length > 0 && !isSp.value) {
          selectedUser.value = contacts.value[0]
        }
      })
    }
    fetchContacts()

    const deleteContact = () => {
      root.$store
        .dispatch(DELETE_CONTACT, {
          data: { user_id: selectedUser.value.user_id }
        })
        .then(() => {
          selectedUser.value = null
          fetchContacts()
          showDeleteContactConfirm.value = false
        })
    }
    const selectUser = user => {
      selectedUser.value = user
    }
    const inputContactSearchWord = e => {
      root.$store.dispatch(INPUT_CONTACT_SEARCH_WORD, e.target.value)
    }
    const clearContactSearchWord = () => {
      root.$store.dispatch(CLEAR_CONTACT_SEARCH_WORD)
    }

    const contactSearchWord = computed(
      () => root.$store.getters.contactSearchWord
    )

    const showSpMenu = ref(false)
    const showSpProfile = ref(false)
    const searchUserInput = ref('')
    const blurInput = () => {
      searchUserInput.value.blur()
    }

    return {
      isSp,
      me,
      loading,
      showAddContactModal,
      showContactMenu,
      showDeleteContactConfirm,
      selectedUser,
      contacts,
      contactSearchWord,
      toggleShowContactMenu,
      deleteContact,
      selectUser,
      inputContactSearchWord,
      clearContactSearchWord,
      USER_CATEGORY,
      CHAT_TYPE,
      showSpMenu,
      showSpProfile,
      searchUserInput,
      blurInput,
      windowHeight: getWindowHeight(195)
    }
  }
})
</script>

<template src="./Other.html" />
<style lang="scss" scoped src="./Other.scss"></style>
<script>
import { computed, defineComponent, ref, reactive } from '@vue/composition-api'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import BusinessProfile from '@/compornents/BusinessProfile/BusinessProfile'
import ManageMenu from '@/compornents/ManageMenu/ManageMenu'
import StaffHistoryModal from '@/compornents/StaffHistoryModal/StaffHistoryModal'
import useMe from '@/comporsables/useMe'
import {
  POST_LOGOUT,
  RESET_PASSWORD,
  GET_STAFFS_STATUS,
  DELETE_OFFICE,
  MAIL_ON,
  MAIL_OFF
} from '@/store/types'
import { getWindowHeight } from '@/utils/window'
export default defineComponent({
  name: 'Other',
  title() {
    return 'その他'
  },
  components: {
    HeaderRight,
    AvatarImg,
    BusinessProfile,
    ManageMenu,
    StaffHistoryModal
  },
  setup(_, { root }) {
    const { me } = useMe(root)
    const layer1 = computed(() => root.$route.params.layer1)
    const layer2 = computed(() => root.$route.params.layer2)

    const logout = () => {
      root.$store.dispatch(POST_LOGOUT).then(() => {
        location.reload()
      })
    }
    const checked = computed(() => me.mail_notice == '1')

    const showLogoutConfirm = ref(false)

    const passwordForm = reactive({
      password: '',
      password_confirmation: ''
    })

    const resetPassword = () => {
      root.$store.dispatch(RESET_PASSWORD, passwordForm).then(() => logout())
    }
    const mailon = () => {
      root.$store.dispatch(MAIL_ON, me.user_id).then(() => location.reload())
    }
    const mailoff = () => {
      root.$store.dispatch(MAIL_OFF, me.user_id).then(() => location.reload())
    }
    const updateNotification = () => {
      if (me.mail_notice == '1') {
        mailoff()
      } else {
        mailon()
      }
    }

    const business_profile = computed(
      () => root.$store.getters.business_profile
    )
    const showModal = ref(false)
    const showMenu = ref(false)
    const office_number = ref(
      me.office_number ? me.office_number : me.other_office_number || false
    )
    const deleteOffice = ref(null)
    const enable_staffs = ref(0)
    const affiliation_groups = ref(0)
    const delOffice = () => {
      showModal.value = false
      root.$store.dispatch(DELETE_OFFICE, office_number.value).then(() => {
        root.$notify({
          title: '',
          message: '退会申請を送信しました',
          type: 'success'
        })
        deleteOffice.value = null
      })
    }
    const clearShowMenu = () => {
      console.log('clearShowMenu!!')
      showMenu.value = false
      deleteOffice.value = null
    }
    const showDeleteOfficeModal = () => {
      deleteOffice.value = office_number.value
      showModal.value = true
      showMenu.value = false
      root.$store.dispatch(GET_STAFFS_STATUS, office_number.value).then(() => {
        enable_staffs.value = root.$store.getters.enable_staffs
        affiliation_groups.value = root.$store.getters.affiliation_groups
      })
    }
    return {
      loading: computed(() => root.$store.getters.loading),
      isSp: computed(() => root.$store.getters.isSp),
      isManage: computed(() => root.$store.getters.isManage),
      me,
      logout,
      layer1,
      layer2,
      showLogoutConfirm,
      updateNotification,
      checked,
      passwordForm,
      resetPassword,
      office_number,
      delOffice,
      showModal,
      deleteOffice,
      clearShowMenu,
      showDeleteOfficeModal,
      showMenu,
      business_profile,
      windowHeight: getWindowHeight(74),
      enable_staffs,
      affiliation_groups,
      mailon,
      mailoff
    }
  }
})
</script>

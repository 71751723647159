<template src="./Contact.html" />
<style lang="scss" scoped src="./Contact.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import { CHAT_TYPE, USER_CATEGORY } from '@/constants/constant'

export default defineComponent({
  name: 'Contact',
  props: {
    page: {
      type: String,
      require: true
    },
    user: {
      type: Object,
      require: true
    },
    isSelected: {
      type: Boolean,
      require: false
    }
  },
  components: {
    AvatarImg
  },
  setup(props, { root, emit }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const checked = ref(props.isSelected)
    const showMenu = ref(false)
    const toggleShowMenu = () => {
      showMenu.value = !showMenu.value
    }

    const selectUser = () => {
      checked.value = !checked.value
      emit('selectUser', { checked: checked.value, user: props.user })
    }

    return {
      isSp,
      showMenu,
      checked,
      USER_CATEGORY,
      CHAT_TYPE,
      toggleShowMenu,
      selectUser
    }
  }
})
</script>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import titleMixin from './utils/titleMixin'
// import '@/utils/typekit'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/ja'
import VueCompositionApi from '@vue/composition-api'
import VueScrollTo from 'vue-scrollto'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { isLocal, isPrd } from '@/utils/common'
import sanitizeHTML from 'sanitize-html'

Vue.config.productionTip = false

Vue.mixin(titleMixin)
Vue.use(ElementUI, { locale })
Vue.use(VueCompositionApi)
Vue.use(VueScrollTo)
Vue.prototype.$sanitize = sanitizeHTML

if (!isLocal()) {
  Sentry.init({
    Vue,
    dsn: isPrd()
      ? 'https://e8e616db65244d0dbfc8eb002e0f0bbc@o850037.ingest.sentry.io/5870484'
      : 'https://5046f62e69bb4b9b87262737b1f4a28e@o850037.ingest.sentry.io/5816802',
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}

Vue.config.errorHandler = function (error) {
  if (error.name === 'NavigationDuplicated') {
    // routerで遷移する時、同じページに遷移しようとすると起こるエラーを回避
    return
  }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import { GET_POST_CODE } from './types'
import { ENDPOINT_POSTCODE_API } from '@/constants/endpoint'
import axios from 'axios'
import axiosJsonpAdapter from 'axios-jsonp'

export default {
  state: {
    address: {
      prefecture_id: null,
      city: null,
      block: null
    }
  },
  mutations: {
    [GET_POST_CODE](state, responseData) {
      if (responseData.results === null) {
        throw new Error()
      }
      state.address.prefecture_id = responseData.results[0].prefcode
      state.address.city = responseData.results[0].address2
      state.address.block = responseData.results[0].address3
    }
  },
  getters: {
    address: state => state.address
  },
  actions: {
    [GET_POST_CODE]: async ({ commit }, zipcode) => {
      if (!/[0-9]{7}/.test(zipcode)) {
        return
      }
      await axios
        .get(ENDPOINT_POSTCODE_API, {
          adapter: axiosJsonpAdapter,
          params: {
            zipcode: zipcode
          }
        })
        .then(response => {
          commit(GET_POST_CODE, response.data)
        })
        .catch(error => {
          console.log(error)
          throw new Error()
        })
    }
  }
}

<template src="./AvatarImg.html" />
<style lang="scss" scoped src="./AvatarImg.scss"></style>
<script>
import { computed, defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'AvatarImg',
  props: {
    name: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    img_url: {
      type: String,
      default: ''
    },
    members: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: 'large'
    },
    isOnline: {
      type: Boolean,
      default: false
    },
    showChooseImg: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const short_name = computed(() =>
      props.name ? props.name.substr(0, 2) : ''
    )
    const font_color = computed(() =>
      ['#FFF4DB'].includes(props.color) ? '#333' : '#fff'
    )

    const S3_URL = `https://s3.ap-northeast-1.amazonaws.com/${process.env.VUE_APP_BUCKET_NAME_PUBLIC}/`

    const has_img_url = ref(false)
    if (props.img_url && props.img_url.startsWith('profile')) {
      has_img_url.value = true
    }

    const img_urls = ref([])
    const setImgUrls = members => {
      if (!members || members.length === 1) {
        img_urls.value = []
        return
      }

      img_urls.value = []
      has_img_url.value = false

      members
        .filter((_, i) => i < 4)
        .forEach(m => {
          if (!m.profile_img_url) {
            img_urls.value = [
              ...img_urls.value,
              {
                color: m.profile_color,
                font_color: ['#FFF4DB'].includes(m.profile_color)
                  ? '#333'
                  : '#fff',
                short_name: m.last_name.substr(0, 1)
              }
            ]
          } else if (m.profile_img_url.startsWith('profile')) {
            has_img_url.value = true
            img_urls.value = [...img_urls.value, S3_URL + m.profile_img_url]
          }
        })
    }

    if (props.members.length > 0) {
      setImgUrls(props.members)
    }

    watch(
      () => props.members,
      v => {
        if (v.length > 0) {
          setImgUrls(v)
        }
      }
    )

    watch(
      () => props.img_url,
      v => {
        console.log(v)
        if (v && v.startsWith('profile')) {
          has_img_url.value = true
        } else {
          has_img_url.value = false
        }
      }
    )

    return {
      short_name,
      font_color,
      has_img_url,
      img_urls,
      S3_URL
    }
  }
})
</script>

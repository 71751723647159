<template src="./ReissuePassword.html" />
<style lang="scss" scoped src="./ReissuePassword.scss"></style>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import { POST_FORGOT_PASSWORD } from '@/store/types'
import '@/comporsables/useVeeValidate'
import { COPY_RIGHT } from '@/constants/constant'

export default defineComponent({
  name: 'ResetPassword',
  title() {
    return 'パスワードの再発行'
  },
  setup(_, { root }) {
    if (root.$store.getters.me && root.$store.getters.me.is_login) {
      root.$router.push({ name: 'home' })
    }

    const email = ref('')
    const sent = ref(false)

    const postForm = () => {
      root.$store
        .dispatch(POST_FORGOT_PASSWORD, { email: email.value })
        .then(() => (sent.value = true))
    }

    return {
      email,
      sent,
      postForm,
      COPY_RIGHT
    }
  }
})
</script>

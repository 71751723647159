import * as http from '@/utils/api'
import { GET_USER_IDENTIFICATION, POST_USER_IDENTIFICATION } from './types'
import { ENDPOINT_USER_IDENTIFICATION } from '@/constants/endpoint'
import { isEmptyObject } from '@/utils/common'
import { ID_CHECKED_STATUS } from '@/constants/constant'

export default {
  state: {
    identification: null
  },
  mutations: {
    [GET_USER_IDENTIFICATION](state, responseData) {
      if (!isEmptyObject(responseData)) {
        state.identification = responseData
      }
    }
  },
  getters: {
    identificationStatus: state =>
      state.identification
        ? state.identification.status
        : ID_CHECKED_STATUS.UNAUTHENTICATED,
    identificationDenialReasonText: state =>
      state.identification ? state.identification.denial_reason_text : ''
  },
  actions: {
    [GET_USER_IDENTIFICATION]: async ({ commit, dispatch }, params) => {
      await http
        .get(ENDPOINT_USER_IDENTIFICATION, params)
        .then(response => {
          commit(GET_USER_IDENTIFICATION, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [POST_USER_IDENTIFICATION]: async ({ dispatch }, params) => {
      await http.post(ENDPOINT_USER_IDENTIFICATION, params).catch(error => {
        http.handleError(dispatch, error)
        throw new Error()
      })
    }
  }
}

import { TOGGLE_FAMILY_CHAT, UNSET_FAMILY_CHAT, SET_FAMILY_CHAT } from './types'

export default {
  state: {
    show_family_chat: false
  },
  mutations: {
    [TOGGLE_FAMILY_CHAT](state) {
      state.show_family_chat = !state.show_family_chat
    },
    [UNSET_FAMILY_CHAT](state) {
      state.show_family_chat = false
    },
    [SET_FAMILY_CHAT](state) {
      state.show_family_chat = true
    }
  },
  getters: {
    show_family_chat: state => state.show_family_chat
  },
  actions: {
    [TOGGLE_FAMILY_CHAT]({ commit }) {
      commit(TOGGLE_FAMILY_CHAT)
    },
    [UNSET_FAMILY_CHAT]({ commit }) {
      commit(UNSET_FAMILY_CHAT)
    },
    [SET_FAMILY_CHAT]({ commit }) {
      commit(SET_FAMILY_CHAT)
    }
  }
}

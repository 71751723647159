<template src="./EditChatModal.html" />
<style lang="scss" scoped src="./EditChatModal.scss"></style>
<script>
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import {
  CLEAR_CONTACT_SEARCH_WORD,
  FETCH_CHATS,
  FETCH_CONTACTS,
  INPUT_CONTACT_SEARCH_WORD,
  POST_CHAT,
  PUT_CHAT,
  PUT_CHAT_MEMBERS,
  PUT_CHAT_OWNER,
  SET_SELECTED_CHAT
} from '@/store/types'
import ProfileView from '@/compornents/ProfileView/ProfileView'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import Contact from '@/compornents/Contact/Contact'
import { CHAT_TYPE, USER_CATEGORY } from '@/constants/constant'
import useMe from '@/comporsables/useMe'
import { getWindowHeight } from '@/utils/window'

export default defineComponent({
  name: 'EditChatModal',
  components: {
    ProfileView,
    AvatarImg,
    Contact
  },
  props: {
    showEditChatModal: {
      type: Boolean,
      require: true
    },
    editChat: {
      type: Object,
      require: false
    }
  },
  setup(props, { emit, root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const contacts = computed(() => root.$store.getters.contacts)
    const contactSearchWord = computed(
      () => root.$store.getters.contactSearchWord
    )
    root.$store.dispatch(FETCH_CONTACTS)
    const selectedUsers = ref([])
    const { me } = useMe(root)

    const form = reactive({
      name: '',
      comment: '',
      chat_type: CHAT_TYPE.GROUP
    })
    const formChatOwners = reactive([])

    if (props.editChat) {
      form.name = props.editChat.chat_name
      form.comment = props.editChat.comment
      selectedUsers.value = props.editChat.members
      formChatOwners.value = props.editChat.members
    }

    const postChat = () => {
      if (!canPost.value) {
        return
      }
      root.$store.dispatch(POST_CHAT, form).then(() => {
        const members = selectedUsers.value.map(u => u.user_id)
        const param = {
          chat_id: root.$store.getters.newChatId,
          members: [...members, me.user_id]
        }
        root.$store.dispatch(PUT_CHAT_MEMBERS, param).then(() => {
          selectedUsers.value = []
          emit('closeEditChatModal')
        })
      })
    }

    const putChat = () => {
      if (!canPost.value) {
        return
      }
      root.$store
        .dispatch(PUT_CHAT, { ...form, chat_id: props.editChat.chat_id })
        .then(() => {
          const members = selectedUsers.value.map(u => u.user_id)
          const param = {
            chat_id: props.editChat.chat_id,
            members: [...members, me.user_id]
          }
          root.$store.dispatch(PUT_CHAT_MEMBERS, param).then(() => {
            selectedUsers.value = []
            emit('closeEditChatModal')
          })

          root.$store.dispatch(FETCH_CHATS).then(() => {
            const chat = root.$store.getters.chats.find(
              c => c.chat_id === props.editChat.chat_id
            )
            root.$store.dispatch(SET_SELECTED_CHAT, chat)
          })
        })
    }

    const selectUser = payload => {
      if (payload.checked) {
        selectedUsers.value = [...selectedUsers.value, payload.user]
      } else {
        selectedUsers.value = selectedUsers.value.filter(
          u => u.user_id !== payload.user.user_id
        )
      }
    }

    const canPost = computed(() => form.name !== '' && form.comment !== '')

    const inputContactSearchWord = e => {
      root.$store.dispatch(INPUT_CONTACT_SEARCH_WORD, e.target.value)
    }
    const clearContactSearchWord = () => {
      root.$store.dispatch(CLEAR_CONTACT_SEARCH_WORD)
    }

    const isSelected = user_id => {
      return selectedUsers.value.find(u => u.user_id === user_id) !== undefined
    }

    const loading = computed(() => root.$store.getters.loading)

    const step = ref(1)

    const changeChatOwner = (user_id, e) => {
      formChatOwners.value = formChatOwners.value.map(u =>
        u.user_id === user_id ? { ...u, is_owner: parseInt(e.target.value) } : u
      )
    }

    const putChatOwnerUserId = ref(0)

    const putChatOwner = () => {
      const formChatOwner = formChatOwners.value.find(
        u => u.user_id === putChatOwnerUserId.value
      )
      root.$store
        .dispatch(PUT_CHAT_OWNER, {
          chat_id: props.editChat.chat_id,
          user_id: formChatOwner.user_id,
          is_owner: formChatOwner.is_owner
        })
        .then(() => (showConfirmPutChatOwner.value = false))
    }

    const showConfirmPutChatOwner = ref(false)

    const isThisChatOwner = computed(() => {
      if (props.editChat) {
        const my = props.editChat.members.find(m => m.user_id === me.user_id)
        return my && my.is_owner
      } else {
        return true
      }
    })
    if (!isThisChatOwner.value) {
      step.value = 2
    }

    return {
      isSp,
      me,
      loading,
      contacts,
      selectedUsers,
      form,
      canPost,
      contactSearchWord,
      inputContactSearchWord,
      clearContactSearchWord,
      USER_CATEGORY,
      selectUser,
      postChat,
      putChat,
      isSelected,
      step,
      putChatOwner,
      putChatOwnerUserId,
      changeChatOwner,
      isThisChatOwner,
      showConfirmPutChatOwner,
      windowHeight: getWindowHeight(95)
    }
  }
})
</script>

<template src="./StaffHistoryModal.html" />
<style lang="scss" scoped src="./StaffHistoryModal.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { GET_STAFF_HISTORIES } from '@/store/types'

export default defineComponent({
  name: 'StaffHistoryModal',
  props: {
    userId: {
      type: Number,
      require: true
    }
  },
  setup(props, { root }) {
    const staffHistories = computed(() => root.$store.getters.staff_histories)
    const staffHistoriesTotalPage = computed(
      () => root.$store.getters.staff_histories_total_page
    )

    const page = ref(1)
    const getStaffHistories = () =>
      root.$store.dispatch(GET_STAFF_HISTORIES, {
        user_id: props.userId,
        page: page.value
      })
    getStaffHistories()

    const nextPage = () => {
      if (page.value === staffHistoriesTotalPage.value) {
        return
      }
      page.value++
      getStaffHistories()
    }

    const prevPage = () => {
      if (page.value === 1) {
        return
      }
      page.value--
      getStaffHistories()
    }

    return {
      loading: computed(() => root.$store.getters.loading),
      staffHistories,
      staffHistoriesTotalPage,
      nextPage,
      prevPage,
      page
    }
  }
})
</script>

import moment from 'moment'
moment.locale('ja')

export function getThisMonthTerm() {
  return [
    moment().format('YYYY-MM-01'),
    moment().endOf('month').format('YYYY-MM-DD')
  ]
}

export function getNow() {
  return moment().format('YYYY-MM-DD HH:mm')
}

export function getNowDateTime() {
  return moment().format('YYYY-MM-DD HH:mm:ss')
}

export function getNowTime() {
  return moment().format('HH:mm')
}

export function getNowHour() {
  return moment().format('HH:00')
}

export function getNowFullString() {
  return moment().format('YYYYMMDDHHmmss')
}

export function getToday() {
  return moment().format('YYYY-MM-DD')
}

export function getThisYear() {
  return moment().format('YYYY')
}

export function getThisMonth() {
  return moment().format('M')
}

export function getLastday() {
  return getNDaysBefore(1)
}

export function getLastMonth() {
  return moment().subtract(1, 'month').format('M')
}

export function getNHoursAfter(n) {
  return moment().add(n, 'hour').format('HH:00')
}

export function getNDaysAfter(n) {
  return moment().add(n, 'day').format('YYYY-MM-DD')
}

export function getNDaysBefore(n) {
  return moment().subtract(n, 'day').format('YYYY-MM-DD')
}

export function getNMonthBefore(n) {
  return moment().subtract(n, 'month').format('YYYY-MM-DD')
}

export function formatDate(date, format) {
  return moment(date).format(format)
}

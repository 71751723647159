import * as http from '@/utils/api'
import {
  FETCH_BUSINESS_PROFILE,
  POST_BUSINESS_PROFILE,
  REGIST_PLATINUM,
  CANCELL_PLATINUM,
  MAP_ON,
  MAP_OFF
} from './types'
import {
  ENDPOINT_BUSINESS_PROFILE,
  ENDPOINT_REGIST_PLATINUM,
  ENDPOINT_CANCELL_PLATINUM,
  ENDPOINT_MAPON,
  ENDPOINT_MAPOFF
} from '@/constants/endpoint'
import { LOADING_TYPE_BUSINESS_PROFILE } from '@/constants/constant'
import { isEmptyObject } from '@/utils/common'

export default {
  state: {
    business_profile: null
  },
  mutations: {
    [FETCH_BUSINESS_PROFILE](state, responseData) {
      if (!isEmptyObject(responseData)) {
        state.business_profile = responseData
      }
    }
  },
  getters: {
    business_profile: state => state.business_profile
  },
  actions: {
    [FETCH_BUSINESS_PROFILE]: async ({ commit, dispatch }) => {
      await http
        .get(ENDPOINT_BUSINESS_PROFILE, {}, LOADING_TYPE_BUSINESS_PROFILE)
        .then(response => {
          commit(FETCH_BUSINESS_PROFILE, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [POST_BUSINESS_PROFILE]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_BUSINESS_PROFILE, params, LOADING_TYPE_BUSINESS_PROFILE)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [MAP_ON]: async ({ dispatch }, params) => {
      await http.get(ENDPOINT_MAPON, params).catch(error => {
        http.handleError(dispatch, error)
        throw new Error()
      })
    },

    [MAP_OFF]: async ({ dispatch }, params) => {
      await http.get(ENDPOINT_MAPOFF, params).catch(error => {
        http.handleError(dispatch, error)
        throw new Error()
      })
    },
    [REGIST_PLATINUM]: async ({ commit, dispatch }) => {
      await http
        .post(ENDPOINT_REGIST_PLATINUM, {}, LOADING_TYPE_BUSINESS_PROFILE)
        .then(response => {
          commit(FETCH_BUSINESS_PROFILE, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [CANCELL_PLATINUM]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_CANCELL_PLATINUM, params, LOADING_TYPE_BUSINESS_PROFILE)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    }
    // [DELETE_OFFICE]: async ({ dispatch }, params) => {
    //   await http
    //     .del(
    //       ENDPOINT_BUSINESS_PROFILE,
    //       { data: params },
    //       LOADING_TYPE_BUSINESS_PROFILE
    //     )
    //     .catch(error => {
    //       http.handleError(dispatch, error)
    //       throw new Error()
    //     })
    // }
  }
}

<template src="./ManageStaff.html" />
<style lang="scss" scoped src="./ManageStaff.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import ManageMenu from '@/compornents/ManageMenu/ManageMenu'
import '@/comporsables/useVeeValidate'
import {
  FETCH_BUSINESS_PROFILE,
  GET_INVITED_STAFFS,
  POST_STAFF
} from '@/store/types'
import {
  MAIL_USAGE_STATUS,
  STAFF_HISTORY_STATUS,
  USER_STATUS
} from '@/constants/constant'

export default defineComponent({
  name: 'ManageStaff',
  title() {
    return 'スタッフ招待 - 介護事業者管理画面'
  },
  components: {
    HeaderRight,
    AvatarImg,
    ManageMenu
  },
  setup(_, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const isManage = computed(() => root.$store.getters.isManage)
    // SPまたは事業管理者以外の場合はホームへ
    if (isSp.value || !isManage.value) {
      root.$router.push({ name: 'home' })
    }

    const email = ref('')

    const postStaff = () =>
      root.$store
        .dispatch(POST_STAFF, { email: email.value })
        .then(() => {
          email.value = ''
          root.$notify({
            title: '',
            message: 'スタッフを招待しました',
            type: 'success'
          })
          getInvitedStaffs()
        })
        .catch(() => {
          email.value = ''
          root.$store.dispatch(FETCH_BUSINESS_PROFILE)
        })

    const page = ref(1)
    const getInvitedStaffs = () =>
      root.$store.dispatch(GET_INVITED_STAFFS, { page: page.value })
    getInvitedStaffs()
    const invitedStaffs = computed(() => root.$store.getters.invited_staffs)

    const convertLabel = status => {
      switch (status) {
        case STAFF_HISTORY_STATUS.INVITING:
          return 'label02'
        case USER_STATUS.REGISTRATION:
          return 'label03'
        case USER_STATUS.TEMPORARY:
          return 'label05'
        case STAFF_HISTORY_STATUS.STOPPED:
          return 'label01'
        case USER_STATUS.DELETED:
          return 'label01'
        default:
          return ''
      }
    }

    const invitedStaffsTotalPage = computed(
      () => root.$store.getters.invited_staffs_total_page
    )

    const nextPage = () => {
      if (page.value === invitedStaffsTotalPage.value) {
        return
      }
      page.value++
      getInvitedStaffs()
    }

    const prevPage = () => {
      if (page.value === 1) {
        return
      }
      page.value--
      getInvitedStaffs()
    }

    return {
      loading: computed(() => root.$store.getters.loading),
      business_profile: computed(() => root.$store.getters.business_profile),
      email,
      postStaff,
      convertLabel,
      invitedStaffs,
      invitedStaffsTotalPage,
      nextPage,
      prevPage,
      MAIL_USAGE_STATUS
    }
  }
})
</script>

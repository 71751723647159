<template src="./MakeReportModal.html" />
<style lang="scss" scoped src="./MakeReportModal.scss"></style>
<script>
import { ref, defineComponent } from '@vue/composition-api'
import { PRINT_STYLE_REPORT } from '@/constants/printStyle'
import { formatDate } from '@/utils/date'
import useMe from '@/comporsables/useMe'

export default defineComponent({
  name: 'MakeReportModal',
  props: {},
  setup(_, { root }) {
    // https://stackoverflow.com/questions/52343006/how-to-print-a-part-of-a-vue-component-without-losing-the-style
    const print = () => {
      const prtHtml = document.getElementById('print-content').innerHTML

      let stylesHtml = PRINT_STYLE_REPORT
      const WinPrint = window.open('', '')

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    <title>支援経過記録 | Minna de care</title>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`)

      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    }

    const reportMessages = ref([])
    let lastDate = ''
    root.$store.getters.reportMessages.forEach(m => {
      const ymd = formatDate(m.created_at, 'YYYYMMDD')
      if (lastDate !== ymd) {
        reportMessages.value = [
          ...reportMessages.value,
          {
            date: formatDate(m.created_at, 'YYYY年M月D日'),
            name: '',
            message: '',
            line: 1
          }
        ]
      }

      const line = m.message.split('\n').length

      lastDate = ymd
      reportMessages.value = [
        ...reportMessages.value,
        {
          date: formatDate(m.created_at, 'H:mm'),
          name: m.name,
          message: m.message,
          line: line
        }
      ]
    })

    if (reportMessages.value.length < 15) {
      const diff = 15 - reportMessages.value.length
      for (let i = 0; i < diff; i++) {
        reportMessages.value = [
          ...reportMessages.value,
          { date: '', name: '', message: '', line: 1 }
        ]
      }
    }

    const userName = ref('')
    const idEditUserName = ref(false)
    const editUserName = e => {
      userName.value = e.target.value
      idEditUserName.value = false
    }

    const editLine = ref(0)
    const clickTd = i => {
      editLine.value = i
    }
    const clearEdit = () => {
      editLine.value = 0
      idEditUserName.value = false
    }

    const { me } = useMe(root)
    return {
      print,
      me,
      reportMessages,
      clickTd,
      editLine,
      clearEdit,
      userName,
      idEditUserName,
      editUserName
    }
  }
})
</script>

import { render, staticRenderFns } from "./StaffHistoryModal.html?vue&type=template&id=38a8684e&scoped=true&"
import script from "./StaffHistoryModal.vue?vue&type=script&lang=js&"
export * from "./StaffHistoryModal.vue?vue&type=script&lang=js&"
import style0 from "./StaffHistoryModal.scss?vue&type=style&index=0&id=38a8684e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38a8684e",
  null
  
)

export default component.exports
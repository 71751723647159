import * as http from '@/utils/api'
import {
  DELETE_MESSAGE,
  POST_MESSAGE,
  PUT_MESSAGE,
  POST_MESSAGE_READ
} from './types'
import { ENDPOINT_MESSAGE, ENDPOINT_MESSAGE_READ } from '@/constants/endpoint'
import { LOADING_TYPE_NONE } from '@/constants/constant'

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    [POST_MESSAGE]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_MESSAGE, params, params.loading_type)
        .catch(error => {
          console.log(error)
          http.handleError(dispatch, error)
        })
    },
    [PUT_MESSAGE]: async ({ dispatch }, params) => {
      await http
        .put(ENDPOINT_MESSAGE, params, LOADING_TYPE_NONE)
        .catch(error => {
          console.log(error)
          http.handleError(dispatch, error)
        })
    },
    [DELETE_MESSAGE]: async ({ dispatch }, params) => {
      await http
        .del(ENDPOINT_MESSAGE, { data: params }, LOADING_TYPE_NONE)
        .catch(error => {
          console.log(error)
          http.handleError(dispatch, error)
        })
    },
    [POST_MESSAGE_READ]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_MESSAGE_READ, params, LOADING_TYPE_NONE)
        .catch(error => {
          console.log(error)
          http.handleError(dispatch, error)
        })
    }
  }
}

export function createImage(file, uploaded_image, useShrink = false) {
  console.log(file)
  const reader = new FileReader()
  reader.onload = e => {
    const img = new Image()
    img.onload = function () {
      const _canvas = document.createElement('canvas')
      const _ctx = _canvas.getContext('2d')
      _canvas.setAttribute(
        'style',
        'opacity:0; position:absolute; z-index:-1; ' +
          'top:-100000000px;left:-1000000000px;'
      )

      if (useShrink) {
        // 幅300pxに縮小
        if (img.width > 300) {
          _canvas.setAttribute('width', 300)
          _canvas.setAttribute('height', (300 * img.height) / img.width)
        } else {
          _canvas.setAttribute('width', img.width)
          _canvas.setAttribute('height', img.height)
        }
      } else {
        _canvas.setAttribute('width', img.width)
        _canvas.setAttribute('height', img.height)
      }
      document.body.appendChild(_canvas)

      _ctx.drawImage(img, 0, 0, _canvas.width, _canvas.height)

      var newImage = _canvas.toDataURL(file.type)
      _canvas.remove()
      uploaded_image.value = newImage
    }
    img.src = e.target.result
  }
  reader.readAsDataURL(file)
}

export function toBlob(base64, type) {
  const bin = atob(base64.replace(/^.*,/, ''))
  const buffer = new Uint8Array(bin.length)
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i)
  }
  try {
    return new Blob([buffer.buffer], {
      type: type
    })
  } catch (e) {
    return false
  }
}

export function isPDF(ext) {
  return ext.toLowerCase() === 'pdf'
}

export function isImage(ext) {
  return ['png', 'jpeg', 'jpg'].includes(ext)
}
